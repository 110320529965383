import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import OTPInput from 'otp-input-react';

import { MainContent, SmallButton, LargeButton } from '../CommonStyles';
import { TokenController } from '../../utils/cachedAssets';
import { UserController, DevicesController, ChallengeController, CredentialsController } from '../../utils/apiProxy';

const EnrollDeviceContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    padding: 0 16px;
`;

const Content = styled.div`
    margin: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Screens = {
    Intro: 'intro',
    AddDevice: 'add-device',
    Confirm: 'confirm',
    VerifyChallenge: 'verify',
}

function EnrollDevice() {
    const history = useHistory();
    const [ user, setUser ] = useState(null);
    const [ message, setMessage ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ currentScreen, setCurrentScreen ] = useState(null);
    const [ isAddingDevice, setIsAddingDevice ] = useState(false);
    const [ newDevice, setNewDevice ] = useState(null);
    const [ isVerifying, setIsVerifying ] = useState(false);
    const [ isSendingChallenge, setIsSendingChallenge] = useState(false);
    const [ verifyCode, setVerifyCode ] = useState(null);
    const [ isConfirming, setIsConfirming ] = useState(false);
    const [ credential, setCredential ] = useState(null);

    useEffect(() => {
        const fetchedToken = TokenController.fetchToken();
        if (!fetchedToken) {
            history.push('/signed-out');
        }
        setMessage('Getting things ready...');
        UserController.fetchUserDetails(fetchedToken).then((result) => {
            setMessage(null);
            setUser(result.user);
            setCredential(result.user.credential);
            setIsLoading(false);
            setCurrentScreen(Screens.Intro);
        }).catch((error) => {
            setIsLoading(false);

            const errorJsx = 
            <>
                { error.message}<br/>
                <button onClick={() => history.push('/auth')}>Log in again.</button>
            </>

            setMessage(errorJsx);
        });
    }, [ history ]);
    
    const handleAddDevice = () => {
        setIsAddingDevice(true);
        DevicesController.createNewDevice().then((newDevice) => {
            setIsAddingDevice(false);
            setNewDevice(newDevice);
            setCurrentScreen(Screens.AddDevice);
        }).catch((error) => {
            console.log('error', error);
            setIsAddingDevice(false);
        });
    }

    const handleVerifyCodeClicked = () => {
        setIsConfirming(true);

        ChallengeController.validate(verifyCode, newDevice.id, user.id)
            .then(([_, token]) => {
                TokenController.storeToken(token); // a new token
                setMessage('Saving factor to profile...');

                CredentialsController.update(
                    TokenController.fetchToken(),
                    credential, 
                    { deviceId: newDevice.id })
                .then((result) => {
                    console.log('credential save: ', result);
                    setIsConfirming(false);
                    setCurrentScreen(Screens.Done);
                }).catch((error) => {
                    console.log('error', error);
                    setMessage('There was a problem.');
                });
        });
    }

    const handleVerifyCodeChanged = (code) => {
        setVerifyCode(code);
    }

    const handleDeviceScanned = () => {
        setIsVerifying(true);
        DevicesController.getDeviceById(newDevice.id).then((device) => {
            if (!device.firebaseToken || !device.name) {
                setMessage('It appears you have not completely set up the device.');
                setIsVerifying(false);
            } else {
                // TODO: create a new device challenge
                setCurrentScreen(Screens.Confirm);
                setNewDevice(device);
                setMessage(null);
                setIsVerifying(false);
            }
        }).catch((error) => {
            console.log('Device error:', error);
            setIsVerifying(false);
        });
    }

    const handleChallengeTestClicked = () => {
        setIsSendingChallenge(true);
        ChallengeController.newMobileChallenge(newDevice.id, user.id)
            .then((_) => {
                setMessage(null);
                setIsSendingChallenge(false);
                setCurrentScreen(Screens.VerifyChallenge);
        });
    }

    const commonHeader = () => (
        <Content>
            <h2>Enroll in Device Authentication</h2>
            { user?.firstName } { user?.lastName }
        </Content>
    );

    const renderUserDevices = () => {
        const devices = user?.devices;

        if (!devices || devices.length === 0) {
            return <p>You you have no devices set up. Click &quot;Add&quot; to begin.</p>
        }

        return (
            <>
            <p>Here are your current devices. Select which one you'd like to set as your login device, or click &quot;<strong>Add</strong>&quot; to add an additional device.</p>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
            {
                devices.map((device) => {
                    return (
                        <div key={device.id} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 175, width: 100, border: '1px solid #a0a0a0', padding: '0 8px', margin: '0 8px'}}>
                            { device.name || 'Unnamed'}
                            <span style={{ marginTop: 8, fontSize: 10, display: 'block' }}>
                                { device.firebaseToken ? 'Ready for notifications' : 'Not set up' }
                            </span>
                        </div>  
                    );
                })
            }
            </div>
            <Content>
                <LargeButton
                    onClick={handleAddDevice}
                    disabled={isAddingDevice}
                >
                    { isAddingDevice ? 'Adding...' : 'Add' }
                </LargeButton>
            </Content>
            </>
        );
    }

    const renderQrCode = () => {
        const QRCode = require('qrcode.react');
        return (
            <>
                <p>Use the <strong>Manyfactor.io Companion</strong> app to scan this QR Code. You will be provided further instructions.</p>
                <p>When finished, click on &quot;Next&quot;</p>
                <p><QRCode value={newDevice?.id} renderAs="svg" /></p>
                {/* TODO: poll the server and wait for a the name to show up in the database */}

                <Content>
                    { message }
                </Content>

                <LargeButton
                    onClick={handleDeviceScanned}
                    disabled={isVerifying}
                >
                    { isVerifying ? 'Verifying...' : 'Next' }
                </LargeButton>
            </>
        );
    }

    const renderCurrentScreen = (currentScreen) => {
        switch (currentScreen) {
            case Screens.Intro:
                return (
                    <>
                    { commonHeader() }
                    <Content>
                        <p>Device authentication is the ability to use the security of your personal device, such as mobile phone, to compliment the security already established by Manyfactor.io.</p>
                        <p>In this specific case, we will use a mobile device to prompt you if you wish to authorize the login attempt to Manyfactor.io.</p>
                        { renderUserDevices() }
                    </Content>
                    </>
                );
            case Screens.AddDevice:
                return (
                    <>
                        { commonHeader() }
                        <Content>
                            { renderQrCode() }
                        </Content>
                    </>
                );
            case Screens.Confirm:
                return (
                    <>
                        { commonHeader() }
                        <Content>
                            <p>Yay! <strong>{ newDevice.name }</strong> has been linked to your account.</p>
                            <p>Let's try it out. Click &quot;Confirm&quot; to test the remote notification of your mobile device.</p>

                            <LargeButton
                                disabled={isSendingChallenge}
                                onClick={handleChallengeTestClicked}
                            >
                                { isSendingChallenge ? 'Setting up...' : 'Confirm' }
                            </LargeButton>
                        </Content>
                    </>
                );
            case Screens.VerifyChallenge: 
                return (
                    <>
                        <Content>
                            <Content>
                                We sent a code to { newDevice.name }, please enter the code specified.
                            </Content>

                            <Content>
                                <OTPInput
                                    value={verifyCode}
                                    onChange={handleVerifyCodeChanged}
                                    autoFocus
                                    OTPLength={6}
                                    otpType="number"
                                    disabled={false}
                                />
                            </Content>

                            <Content>
                                {
                                    <LargeButton
                                        disabled={isConfirming || verifyCode?.length < 6 }
                                        onClick={handleVerifyCodeClicked}
                                    >
                                        { isConfirming ? 'Verifying...' : 'Verify' }
                                    </LargeButton>
                                }
                            </Content>
                        </Content>
                    </>
                );
            case Screens.Done:
                return (
                    <>
                        { commonHeader() }

                        <Content>
                            Great news: we're all set! You can now use your device <strong>{ newDevice.name }</strong>as an additional factor!
                        </Content>

                        <Content>
                            <LargeButton onClick={() => history.push('/me')}>
                                Back to Profile
                            </LargeButton>
                        </Content>
                    </>
                );
            default:
                return (
                <>
                    { commonHeader() }

                    { message && 
                        <Content>
                            { message }
                        </Content>
                    }
                </>
                );
        }
    }

    return (
        <EnrollDeviceContainer>
            <MainContent>
                { isLoading ? <em>Loading...</em> : renderCurrentScreen(currentScreen) }
                <SmallButton onClick={() => history.push('/me')}>
                    Go back
                </SmallButton>
            </MainContent>
        </EnrollDeviceContainer>
    );
}

export default EnrollDevice;