import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import OTPInput from 'otp-input-react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { get } from '@github/webauthn-json';

import { AuthController, ChallengeController, ChallengeTypes } from '../../utils/apiProxy';
import { TokenController } from '../../utils/cachedAssets';
import {
    FirstThirdContainer,
    Form,
    LargeButton,
    PaddedContainer,
    CenteredContainer,
    Input,
    ChromelessButton,
    ErrorMessage,
    LastThirdContainer,
    LargeBlockText,
} from '../CommonStyles';
import { captureRejectionSymbol } from 'events';

const AppHeader = styled.h1`
  font-size: ${props => props.secondary ? '1.4rem' : '2rem'};  
  font-weight: ${props => props.secondary ? 'bold' : 'regular'};
  ${props => props.secondary && `
    font-weight: bold;
    line-height: 1rem;
    
    width: 100%;
    text-align: center;
    padding: 16px;`}
`;

function Authentication() {
    const [email, setEmail] = useState('');
    const [sms, setSms] = useState(null);
    const [sub, setSub] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [enrollmentStep, setEnrollmentStep] = useState(-1);
    const [OTP, setOTP] = useState('');
    const [ isValidating, setIsValidating ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [canSubmitChallenge, setCanSubmitChallenge] = useState(false);
    const [shouldLogin, setShouldLogin] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [destination, setDestination] = useState(null);
    const [otpVerify, setOtpVerify] = useState(null);
    const [ credentialResponse, setCredentialResponse ] = useState(null);
    const [ loginMethod, setLoginMethod ] = useState(null);

    const history = useHistory();
    const match = useRouteMatch();

    useEffect(() => {
        if (match.params && match.params.action) {
            const isLogin = match.params.action === 'login';

            if (isLogin) {
                setEnrollmentStep(0);
            }

            setShouldLogin(isLogin);
        }
    }, [match.params.action]);


    const otpChanged = (input) => {
        setOTP(input);
        checkOtp(input); // TODO: maybe this can be a useEffect...
    }

    const validateOtp = () => {
        setIsLoading(true);
        setLoadingMessage('Validating token...');

        ChallengeController.validate(OTP, destination, sub).then(([response, jwtToken]) => {
            // response has a header... can we get it from here?
            if (response === 'success') {
                setIsLoading(false);
                setEnrollmentStep(2);
                setIsSignedIn(true);
                TokenController.storeToken(jwtToken);
            }
        })
        .catch((err) => {
            setIsLoading(false);
            setErrorMessage(err.message);
        });
    }

    const handleOtpVerifyChanged = (e) => {
        setOtpVerify(e.target.value);
    }

    const checkOtp = (input) => {
        setCanSubmitChallenge(input && input.length === 6);
    }

    const handleYubikeyVerifyClicked = () => {
        setIsLoading(true);
        ChallengeController.validateYubikeyOtp(
            otpVerify,
            email,
            sub,
        ).then(([status, newToken]) => {
            if (status === 'success') {
                TokenController.storeToken(newToken);
                setIsLoading(false);
                setEnrollmentStep(2);
            }
        }).catch((error) => {
            setIsLoading(false);
            setErrorMessage(`There was an issue validating your Yubikey: ${error.message}`);
        });
    }

    const handleFidoChallengeClicked = () => {
        if (!credentialResponse) {
            setErrorMessage('There was no response to challenge against.');
            return;
        }

        setIsValidating(true);
        get(credentialResponse).then((result) => {
            setLoadingMessage('Validating challenge response...');

            ChallengeController.verifyFido2Challenge(
                result,
                email,
                sub,
            ).then(([response, token]) => {
                if (response === 'success') {
                    TokenController.storeToken(token);
                    setIsLoading(false);
                    setLoadingMessage(null);
                    setErrorMessage(null);
                    setIsValidating(false);
                    setEnrollmentStep(2);
                    setIsSignedIn(true);
                } else {
                    setIsLoading(false);
                    setLoadingMessage(null);
                    setIsValidating(false);
                    setErrorMessage('There was an error. Try again.');
                }
            }).catch((err) => {
                setIsLoading(false);
                setLoadingMessage(null);
                setIsValidating(false);
                setErrorMessage(`Verification Error: ${err.message}`);
            });
        }).catch((err) => {
            setIsLoading(false);
            setLoadingMessage(null);
            setIsValidating(false);
            setErrorMessage(`Local Challenge Error: ${err.message}`);
        });
    }

    const handleSendAnotherClicked = () => {
        setIsLoading(true);
        setLoadingMessage('Setting up another challenge...');

        // TODO: don't rely on state vars
        ChallengeController.newEmail(email, sub)
        .then((newEmailChallengeResponse) => {
            if (newEmailChallengeResponse === 'ok') {
                setEnrollmentStep(1);
                setLoadingMessage('');
                setIsLoading(false);
            }
        });
    }

    const handleEmailSignin = (e) => {
        e.preventDefault();

        if (!email || !email.length) {
            setErrorMessage('You must specify an email address.');
            return;
        }

        setIsLoading(true);
        setLoadingMessage('Checking credential...');

        AuthController.signIn(email).then((response) => {
            // todo: 
            const { credential } = response;

            // TODO: determine preferred factor. For now, we will use email
            const { email, sms, user, deviceId } = credential;

            if (!email || !user) {
                setIsLoading(false);
                setErrorMessage('No email to be able to challenge against.');
            }

            setEmail(email);
            setSms(sms);
            setSub(user.id);

            // preference? sms | email | yubikey | fido
            const { preferredLoginMethod } = user;

            setLoadingMessage('Setting up challenge...');
            if (preferredLoginMethod === ChallengeTypes.FIDO) {
                ChallengeController.getFido2ChallengeOptions(credential.id).then((credentialChallengeResponse) => {
                    setCredentialResponse(credentialChallengeResponse);
                    setLoginMethod(preferredLoginMethod);
                    setDestination('');
                    setEnrollmentStep(1);
                    setIsLoading(false);
                    setLoadingMessage('');
                });
            } else
            if (preferredLoginMethod === ChallengeTypes.YUBIKEYOTP) {
                ChallengeController.newYubikeyOtp(email, user.id).then((yubikeyResponse) => {
                    if (yubikeyResponse === 'ok') {
                        setDestination('');
                        setLoginMethod(preferredLoginMethod);
                        setEnrollmentStep(1);
                        setIsLoading(false);
                        setLoadingMessage('');
                    }
                });
            } else
            if (preferredLoginMethod === ChallengeTypes.SMS) {
                ChallengeController.newSms(sms, user.id).then((smsResponse) => {
                    if (smsResponse === 'ok') {
                        setDestination(sms);
                        setLoginMethod(preferredLoginMethod);
                        setEnrollmentStep(1);
                        setIsLoading(false);
                        setLoadingMessage('');
                    }
                });
            } else
            if (preferredLoginMethod === ChallengeTypes.EMAIL) {
                ChallengeController.newEmail(email, user.id).then((response) => {
                    if (response === 'ok') {
                        setDestination(email);
                        setLoginMethod(preferredLoginMethod);
                        setEnrollmentStep(1);
                        setIsLoading(false);
                        setLoadingMessage('');
                    }
                });
            } else
            if (preferredLoginMethod === ChallengeTypes.DEVICE) {
               ChallengeController.newMobileChallenge(deviceId, user.id).then((response) => {
                   if (response === 'ok') {
                       setDestination(deviceId);
                       setLoginMethod(preferredLoginMethod);
                       setEnrollmentStep(1);
                       setIsLoading(false);
                       setLoadingMessage('');
                   }
                });
            }
        }).catch((err) => {
            setIsLoading(false);
            setErrorMessage(err.message);
        });
    }

    const renderLoginStep = (currentStep) => {
        switch (currentStep) {
            case 0:
                return (
                    <Form onSubmit={handleEmailSignin}>
                        <Input type="email" placeholder="your email address" onChange={(e) => setEmail(e.target.value)} />

                        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

                        <LargeButton type="submit">Next</LargeButton>
                    </Form>
                )
            case 1:
                let loginJsx;

                
                switch (loginMethod) {
                    case ChallengeTypes.EMAIL:
                    case ChallengeTypes.SMS:
                    case ChallengeTypes.DEVICE:
                        const nameForDestination = destination !== email || destination !== sms ? 'your mobile device.' : destination;

                        loginJsx = 
                        (<>
                            <p>A 6-digit token has been sent to {nameForDestination}. Check {nameForDestination}, and input the code below.</p>

                            <Form onSubmit={validateOtp}>
                                <OTPInput
                                    value={OTP}
                                    onChange={otpChanged}
                                    autoFocus
                                    onSubmit={validateOtp}
                                    OTPLength={6}
                                    otpType="number"
                                    disabled={false}
                                />

                                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

                                <LargeButton
                                    type="button"
                                    onClick={validateOtp}
                                    disabled={!canSubmitChallenge}
                                >
                                    Validate
                                </LargeButton>
                            </Form>
                        </>);
                        break;
                    case ChallengeTypes.YUBIKEYOTP:
                        loginJsx = 
                        (
                            <>
                                <p>Sign in requires the presence of your Yubikey OTP hardware.</p>
                                <p>Plug it in, and press the hardware button.</p>
                                <Input
                                    onChange={handleOtpVerifyChanged}
                                    placeholder="Your Yubikey OTP"
                                    autoFocus={true}
                                />
                                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                                <PaddedContainer>
                                    <LargeButton
                                        type="button"
                                        onClick={handleYubikeyVerifyClicked}
                                        disabled={(!otpVerify || otpVerify.length < 44)}
                                    >
                                        Validate
                                    </LargeButton>
                                </PaddedContainer>
                            </>
                        );
                        break;
                    case ChallengeTypes.FIDO:
                        loginJsx = 
                        (
                            <>
                                <p>Sign in requires the presence of your FIDO-capable hardware.</p>
                                <p>Plug it in, and click to proceed with the challenge.</p>
                                
                                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

                                <PaddedContainer>
                                    <LargeButton
                                        type="button"
                                        onClick={handleFidoChallengeClicked}
                                        disabled={isValidating}
                                    >
                                        Validate
                                    </LargeButton>
                                </PaddedContainer>
                            </>
                        );
                        break;
                    default:
                        loginJsx = <>?</>;
                }

                return (
                    <CenteredContainer>
                        { loginJsx }
                    </CenteredContainer>
                );
            case 2:
                return (
                    <CenteredContainer>
                        <h2>Looks like you're all good!</h2>
                        <p>We should send some instructions here on adding another factor.</p>
                        <LargeButton onClick={() => history.push('/me')}>Go to Your Profile</LargeButton>
                    </CenteredContainer>
                );
            default:
                return (<div>What?</div>);
        }
    }

    const renderSignupStep = (currentStep) => {
        switch (currentStep) {
            case 0:
                return (
                    <Form onSubmit={handleEmailSubmit}>
                        <Input type="text" placeholder="your first name" onChange={(e) => setFirstName(e.target.value)} />
                        <Input type="text" placeholder="your last name" onChange={(e) => setLastName(e.target.value)} />
                        <Input type="email" placeholder="your email address" onChange={(e) => setEmail(e.target.value)} />

                        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

                        <LargeButton type="submit">Next</LargeButton>
                    </Form>
                );
            case 1:
                return (
                    <CenteredContainer>
                        <p>A 6-digit token has been sent to {email}. Check your email, and input the code below.</p>
                        <p><strong>Note: it may be in your SPAM or Archived Folders</strong>.</p>
                        <p>If you've checked there, and you still haven't received it, <ChromelessButton onClick={handleSendAnotherClicked}>send another</ChromelessButton>. This will clear the old challenge, and set up a new one.</p>

                        <Form onSubmit={validateOtp}>
                            <OTPInput
                                value={OTP}
                                onChange={otpChanged}
                                autoFocus
                                OTPLength={6}
                                otpType="number"
                                disabled={false}
                            />

                            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                            <LargeButton
                                type="button"
                                onClick={validateOtp}
                                disabled={!canSubmitChallenge}
                            >
                                Validate
                            </LargeButton>
                        </Form>
                    </CenteredContainer>
                );
            case 2:
                return (
                    <CenteredContainer>
                        <h2>Looks like you're all good!</h2>
                        <p>We should send some instructions here on adding another factor.</p>
                        <LargeButton onClick={() => history.push('/me')}>Go to Your Profile</LargeButton>
                    </CenteredContainer>
                );
            default:
                return (
                    <>
                        <PaddedContainer>
                            <FirstThirdContainer>
                                <p><LargeBlockText>Passwords as a security mechanism is dead. </LargeBlockText>A password can be easily guessed, be <a target="_blank" rel="noopener noreferrer" href="https://www.troyhunt.com/introducing-306-million-freely-downloadable-pwned-passwords/">made available</a> through the web, and are otherwise a nuisance.</p>
                                <p>2FA (2-Factor-Authentication) as it exists popularly today is pretty good, but still has their risks, and you end up providing a part of your personal identity (your phone number) to a third party whom you may not trust (nor should you, according to reports of data breaches even amongst the largest, most trust-worthy companies).</p>
                                <p><strong>Manyfactor.io</strong> is a service that compliments the existing common 2FA infrastructure employed by most services, making it more secure by anonymizing your email or SMS that you provide them, and protecting the corresponding token requests by stronger factors, or even a combination of them.</p>
                                <p>Our passion is simple: <strong>Eliminate Passwords</strong>, and our goal is to eventually not require this service, and instead help in the cause of declaring the death of the password, and to rush in the era of more secure methods of authentication that <em>already exists</em>, but for variuos reasons, are not yet prevalent.</p>
                            </FirstThirdContainer>
                            <LastThirdContainer>
                                <LargeBlockText>As part of being a Manyfactor.io user, you should be aware that:</LargeBlockText>
                                
                                <ul>
                                    <li><strong>Your data is safe here.</strong> We employ end-to-end encryption for data in transit (such as the messages you receive), encryption for data-at-rest (such as your email address, phone number, and security tokens), and store the bare minimum about you in order to run the service (your name).</li>
                                    <li><strong>Billing information</strong> is required everytime you make a purchase through Manyfactor.io, and is protected by the third-party billing service (Stripe), and never transferred or stored by us.</li>
                                    <li><strong>Authorizations are never stored in the database,</strong> but in a protected memory space, and are removed after use (successfully or unsuccessfully). The hardware is hosted in a protected-access data center (DigitalOcean).</li>
                                    <li><strong>We will never track you.</strong> We do not emply the use of cookies, and upon accessing your account, we limit the validity time of your session. While this means you may be asked to sign in again after this time has lapsed, this also means that it's safe to access your account on any browser, anywhere, without fear that your login information will be left over. We clean things up nicely for you, should you explicitly choose to Sign Out from your dashboard.</li>
                                    <li><strong>We will try our best, but there are no guarantees.</strong> We redirect messages automatically and behind the scenes; we employ auto-scaling technologies to handle busy times (such as business-morning logins, and evening-time M2M activities) and perform system maintenance and backups. The server is seldom ever down, but it may be slow on rare occasions.</li>
                                    <li><strong>You agree to not use Manyfactor.io for activity considered to be illegal or immoral.</strong> While we never store or read the contents of your messages, we are obligated to monitor the platform for suspicious activity, and will first notify you if we have detected something (and potentially suspend your account). If we cannot come to a resolution between us, we will not hesitate to participate with authorities (only upon request).</li>
                                </ul>
                            </LastThirdContainer>
                            <CenteredContainer>
                                <p>With all of that out of the way, we can start by setting up an account.</p>
                                <LargeButton onClick={() => setEnrollmentStep(0)}>
                                    I agree, let's Sign Up!
                                </LargeButton>
                            </CenteredContainer>
                        </PaddedContainer>
                    </>
                );
        }
    }

    const renderLoading = () => {
        return (
            <PaddedContainer>{loadingMessage || 'Loading...'}</PaddedContainer>
        );
    }

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        setLoadingMessage('Getting things ready...');
        setIsLoading(true);

        if (!email) {
            setErrorMessage('You must specify a valid email address');
            setIsLoading(false);
            return;
        }

        // create a user
        AuthController.signUp(email, firstName, lastName).then((signUpResponse) => {
            const { user } = signUpResponse;

            setSub(user.id);
            setDestination(email);

            setErrorMessage();
            setLoadingMessage('Setting up challenge...');

            ChallengeController.newEmail(email, user.id)
                .then((newEmailChallengeResponse) => {
                    if (newEmailChallengeResponse === 'ok') {
                        setEnrollmentStep(1);
                        setLoadingMessage('');
                        setIsLoading(false);
                    }
                });
        }).catch((error) => {
            console.error(error);
            setErrorMessage(error);
        });
    }

    const handleLoginClicked = () => {
        setEnrollmentStep(0);
        // setShouldLogin(true);
        history.push('/auth/login');
    }

    const handleSignupClicked = () => {
        setEnrollmentStep(0);
        history.push('/auth/signup');
    }

    return (
        <CenteredContainer>
            <AppHeader>Manyfactor.io</AppHeader>

            <h2>{shouldLogin ? 'Log In' : 'Sign Up'}</h2>

            {
                shouldLogin ?
                    (
                        <div>
                            {isLoading ? renderLoading() : renderLoginStep(enrollmentStep)}
                        </div>
                    )
                    : (
                        <div>
                            {isLoading ? renderLoading() : renderSignupStep(enrollmentStep)}
                        </div>
                    )

            }

            {
                isSignedIn
                    ? null
                    : (
                        <PaddedContainer>
                            {
                                shouldLogin
                                    ? (<div>Need an account? <ChromelessButton type="button" onClick={handleSignupClicked}>Create one</ChromelessButton>.</div>)
                                    : (<div>Already have an account? <ChromelessButton type="button" onClick={handleLoginClicked}>Login instead</ChromelessButton>.</div>)
                            }
                        </PaddedContainer>
                    )
            }

        </CenteredContainer>
    );
}

export default Authentication;
