import jwt_decode from 'jwt-decode';

export const ChallengeTypes = {
    SMS: 'sms',
    EMAIL: 'email',
    DEVICE: 'device',
    YUBIKEYOTP: 'yubikey-otp',
    FIDO: 'fido',
    KEEP: 'keep',
};

export const SourceTypes = {
    SMS: 'sms',
    SHARED_SMS: 'shared-sms',
    EMAIL: 'email',
}

const getHeaders = (token) => {
    const headers = {
        'Content-Type': 'application/json',
        'User-agent': 'manyfactor-react',
    };
    if (token) {
        headers['x-access-token'] = token;
    }

    return headers;
}

const decodeJwt = (jwt) => {
    var decodeJwt = jwt_decode(jwt);
    return decodeJwt;
}

export const ChallengeController = {
    newEmail: (email, sub) => {
        const options = {
            method: 'post',
            headers: getHeaders(),
            body: JSON.stringify({
                type: ChallengeTypes.EMAIL,
                destination: email,
                sub,
            }),
        };

        return fetch('/api/challenges/new', options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                const { status } = parsedApiResponse;
                if (status === 'ok') {
                    return status;
                }
            }).catch((error) => {
                // not sure what error looks like yet
                throw new Error(error);
            });
    },

    newSms: (sms, sub) => {
        const options = {
            method: 'post',
            headers: getHeaders(),
            body: JSON.stringify({
                type: ChallengeTypes.SMS,
                destination: sms,
                sub,
            }),
        };

        return fetch('/api/challenges/new', options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                const { status } = parsedApiResponse;
                if (status === 'ok') {
                    return status;
                }
            }).catch((error) => {
                // not sure what error looks like yet
                throw new Error(error);
            });
    },

    newMobileChallenge: (deviceId, sub) => {
        const options = {
            method: 'post',
            headers: getHeaders(),
            body: JSON.stringify({
                type: ChallengeTypes.DEVICE,
                destination: deviceId,
                sub,
            }),
        };

        return fetch('/api/challenges/new', options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                const { status } = parsedApiResponse;
                if (status === 'ok') {
                    return status;
                }
            }).catch((error) => {
                // not sure what error looks like yet
                throw new Error(error);
            });
    },

    newYubikeyOtp: (email, sub) => {
        const options = {
            method: 'post',
            headers: getHeaders(),
            body: JSON.stringify({
                type: ChallengeTypes.YUBIKEYOTP,
                destination: email,
                sub,
            }),
        };

        return fetch('/api/challenges/new', options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                const { status } = parsedApiResponse;
                if (status === 'ok') {
                    return status;
                }
            }).catch((error) => {
                // not sure what error looks like yet
                throw new Error(error);
            });
    },

    validate: (challengeResponse, principal, sub) => {
        let token; // this endpoint will send back a JWT token
        const options = {
            method: 'post',
            headers: getHeaders(),
            body: JSON.stringify({
                challengeResponse,
                principal,
                sub,
            }),
        };

        return fetch('/api/challenges/validate', options)
            .then((apiResponse) => {
                token = apiResponse.headers.get('x-access-token');
                if (!token) {
                    throw new Error('There was a problem with login. A JWT was not issued.');
                }
                return apiResponse.json();
            })
            .then((parsedApiResponse) => {
                const { status, error } = parsedApiResponse;
                if (error) {
                    throw new Error(error);
                }

                if (status === 'success') {
                    return [parsedApiResponse.status, token];
                }

                throw new Error('Could not verify');
            })
            .catch((error) => {
                throw new Error(error.message);
            });
    },

    validateYubikeyOtp: (yubotp, principal, sub) => {
        let token; // this endpoint will send back a JWT token
        const options = {
            method: 'post',
            headers: getHeaders(),
            body: JSON.stringify({
                challengeResponse: ChallengeTypes.YUBIKEYOTP,
                principal,
                sub,
                yubotp,
            }),
        };

        return fetch('/api/challenges/validate', options)
            .then((apiResponse) => {
                token = apiResponse.headers.get('x-access-token');
                if (!token) {
                    throw new Error('JWT not issued');
                }
                return apiResponse.json();
            })
            .then((parsedApiResponse) => {
                const { status, error } = parsedApiResponse;
                if (error) {
                    throw new Error(error);
                }

                if (status === 'success') {
                    return [parsedApiResponse.status, token];
                }

                throw new Error('Could not verify');
            })
            .catch((error) => {
                throw new Error(error.message);
            });
    },

    getFido2Options: (userId) => {
        const options = {
            method: 'get',
            headers: getHeaders(),
        };

        return fetch(`/api/challenges/fido2-options/${userId}`, options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                return parsedApiResponse;
            })
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    },

    getFido2ChallengeOptions: (challengeId) => {
        const options = {
            method: 'get',
            headers: getHeaders(),
        };

        return fetch(`/api/challenges/fido2-challenge/${challengeId}`, options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                return parsedApiResponse;
            })
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    },

    verifyFido2Challenge: (challengeResponse, principal, sub) => {
        let token;

        const options = {
            method: 'post',
            headers: getHeaders(),
            body: JSON.stringify({
                challengeResponse,
                principal,
                sub,
            }),
        };

        return fetch(`/api/challenges/fido2-challenge`, options)
            .then((apiResponse) => {
                token = apiResponse.headers.get('x-access-token');
                if (!token) {
                    throw new Error('JWT not issued');
                }
                return apiResponse.json();
            })
            .then((parsedApiResponse) => {
                const { status, error } = parsedApiResponse;
                if (error) {
                    throw new Error(error);
                }

                if (status === 'success') {
                    return [status, token];
                }

                return parsedApiResponse;
            })
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    }
}

export const CredentialsController = {
    update: (token, credential, newData) => {
        if (!token) {
            throw new Error('No token specified.');
        }

        if (!credential) {
            throw new Error('No credential specified.');
        }
        const decoded = decodeJwt(token);

        const { sub } = decoded;
        if (!sub) {
            throw new Error('Not a valid token');
        }

        const options = {
            method: 'put',
            headers: getHeaders(token),
            body: JSON.stringify(newData),
        };

        return fetch(`/api/auth/update/${credential.id}`, options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                const { credential } = parsedApiResponse;
                if (!credential) {
                    // didnt work
                    throw new Error('No user returned.');
                }

                return ({ credential });
            })
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    },
}

export const AuthController = {
    signUp: (email, firstName, lastName) => {
        const options = {
            method: 'post',
            headers: getHeaders(),
            body: JSON.stringify({
                email,
                firstName,
                lastName,
            }),
        };

        return fetch('/api/auth/signup', options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                const { user } = parsedApiResponse;
                if (!user || !user.credential) {
                    // didnt work
                    return;
                }

                return ({ user });
            })
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    },

    signIn: (email) => {
        const options = {
            method: 'post',
            headers: getHeaders(),
            body: JSON.stringify({
                email,
            }),
        };

        return fetch('/api/auth/signin', options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {                
                const { credential } = parsedApiResponse;
                if (!credential || !credential.user || !credential.user.id) {
                    // didnt work
                    throw new Error('No such user.');
                }

                return ({ credential });
            })
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    }
}

export const UserController = {
    fetchUserDetails: (token) => {
        if (!token) {
            throw new Error('No token specified.');
        }

        const decoded = decodeJwt(token);

        const { sub } = decoded;
        if (!sub) {
            throw new Error('Not a valid token');
        }

        const options = {
            method: 'get',
            headers: getHeaders(token),
        };

        return fetch(`/api/users/${sub}`, options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                const { user } = parsedApiResponse;
                if (!user) {
                    // didnt work
                    throw new Error('No user returned.');
                }

                return ({ user });
            })
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    },
    update: (token, newData) => {
        if (!token) {
            throw new Error('No token specified.');
        }

        const decoded = decodeJwt(token);

        const { sub } = decoded;
        if (!sub) {
            throw new Error('Not a valid token');
        }

        const options = {
            method: 'put',
            headers: getHeaders(token),
            body: JSON.stringify(newData),
        };

        return fetch(`/api/users/${sub}`, options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                const { user } = parsedApiResponse;
                if (!user) {
                    // didnt work
                    throw new Error('No user returned.');
                }

                return ({ user });
            })
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    }
}

export const RedirectsController = {
    deleteOpenRequest: (token, redirectId) => {
        const options = {
            method: 'delete',
            headers: getHeaders(token),
        };

        return fetch(`/api/webhooks/${redirectId}`, options)
            .then((_) => '')
            .then((_) => {
                return {status: 'ok'};
            }).catch((error) => {
                throw new Error(error);
            });
    },

    listOpenRedirects: (token, redirectId) => {
        const options = {
            method: 'get',
            headers: getHeaders(token),
        };

        return fetch(`/api/webhooks/${redirectId}`, options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                return parsedApiResponse;
                // const { redirect } = parsedApiResponse;
                // if (redirect) {
                //     return redirect;
                // }
                // throw new Error('There was no response');
            }).catch((error) => {
                // not sure what error looks like yet
                throw new Error(error);
            });
    },

    // TODO: also require token
    add: (payload, userId) => {
        const options = {
            method: 'post',
            headers: getHeaders(),
            body: JSON.stringify(payload),
        };
    
        return fetch(`/api/users/${userId}/redirects`, options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                const { redirect } = parsedApiResponse;
                if (redirect) {
                    return redirect;
                }
                throw new Error('There was no response');
            }).catch((error) => {
                // not sure what error looks like yet
                throw new Error(error);
            });
    },

    delete: (redirectsId, userId) => {
        const options = {
            method: 'delete',
            headers: getHeaders(),
        };
    
        return fetch(`/api/users/${userId}/redirects/${redirectsId}`, options)
            .then((apiResponse) => '')
            .then((_) => {
                return;
            }).catch((error) => {
                // not sure what error looks like yet
                throw new Error(error);
            });
    },
}

export const NumbersController = {
    deleteNumber: (token, number) => {
        const options = {
            method: 'delete',
            headers: getHeaders(token),
        };

        return fetch(`/api/numbers/${number}`, options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                return parsedApiResponse;
            }).catch((error) => {
                throw new Error(error.message);
            });
    },

    searchNumber: (region) => {
        const options = {
            method: 'get',
            headers: getHeaders(),
        };

        return fetch(`/api/numbers/${region}`, options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                return parsedApiResponse;
            }).catch((err) => {
                throw new Error(err.message);
            });
    },

    purchaseNumber: (token, phoneNumber) => {
        const options = {
            method: 'post',
            headers: getHeaders(token),
        }

        return fetch(`/api/numbers/purchase/${phoneNumber}`, options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                console.log('react got a response', parsedApiResponse);
                return parsedApiResponse;
            }).catch((err) => {
                throw new Error(err.message);
            });
    }
}

export const ProvidersController = {
    fetchAllProviders: () => {
        const options = {
            method: 'get',
            headers: getHeaders(),
        };

        return fetch(`/api/providers`, options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                return parsedApiResponse?.providers;
            }).catch((err) => {
                throw new Error(err.message);
            });
    },

    getNumberForProvider: (providerNumber) => {
        const options = {
            method: 'get',
            headers: getHeaders(),
        }

        return fetch(`/api/numbers/shortcode/${providerNumber}`, options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                return parsedApiResponse.availableNumber || {};
            }).catch((err) => {
                throw new Error(err.message);
            });
    }
}

export const DevicesController = {
    createNewDevice() {
        const options = {
            method: 'post',
            headers: getHeaders(),
        };

        return fetch('/api/devices', options)
            .then((apiResponse) => apiResponse.json())
            .then((parsedApiResponse) => {
                return parsedApiResponse.device;
            })
            .catch((err) => {
                throw new Error(err.message);
            });
    },

    getDeviceById(deviceId) {
        const options = {
            method: 'get',
            headers: getHeaders(), // TODO: needs jwt, and userId assoication
        }

        return fetch(`/api/devices/${deviceId}`, options)
            .then((apiResponse) => {
                return apiResponse.json();
            })
            .then((parsedApiResponse) => {
                return parsedApiResponse.device || {};
            }).catch((err) => {
                throw new Error(err.message);
            });
    }
}
