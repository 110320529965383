import React, { useState, useEffect } from 'react';
import { ImportantParagraph, LargeButton, PaddedContainer, MainContent } from '../CommonStyles';
import { ChallengeController, UserController, CredentialsController } from '../../utils/apiProxy';
import { TokenController } from '../../utils/cachedAssets';
import { useHistory } from 'react-router-dom';
import { create, get } from '@github/webauthn-json';

function EnrollFido2() {
    const [ currentScreen, setCurrentScreen ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ credential, setCredential ] = useState(null);
    const [ user, setUser ] = useState(null);
    const [ message, setMessage ] = useState(null);
    const [ credentialOptions, setCredentialOptions ] = useState(null);
    const [ loadingMessage, setLoadingMessage ] = useState(null);

    const history = useHistory();

    useEffect(() => {
        if (!credentialOptions) {
            return;
        }

        create({publicKey: credentialOptions}).then((result) => {
            setLoadingMessage('Storing credentials securely on the server.');
            setIsLoading(true);

            CredentialsController.update(TokenController.fetchToken(), credential, {fido2: result}).then((response) => {
                setLoadingMessage(null);
                setIsLoading(false);
                setCurrentScreen('validateFido');
            });
        });
    }, [ credentialOptions ]);

    useEffect(() => {
        const token = TokenController.fetchToken();
        if (!token) {
            history.push('/signed-out');
        }

        setIsLoading(true);
        UserController.fetchUserDetails(token).then((result) => {
            setCredential(result.user.credential);
            setUser(result.user);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            const errorJsx = 
            <>
                { error.message}<br/>
                <button onClick={() => history.push('/auth')}>Log in again.</button>
            </>

            setMessage(errorJsx);
        });
    }, []);

    const handleGetStartedClicked = () => {
        ChallengeController.getFido2Options(user.id).then((result) => {
            setCredentialOptions(result);
            setCurrentScreen('askForCredentials');
        });
    }

    const handleGoBackClicked = () => {
        history.push('/me');
    }

    const handleTestChallengeClicked = () => {
        setLoadingMessage('Requesting validation protocols...');
        setIsLoading(true);

        ChallengeController.getFido2ChallengeOptions(credential.id).then((credentialChallengeResponse) => {
            get(credentialChallengeResponse).then((result) => {
                setLoadingMessage('Validating challenge response...');

                ChallengeController.verifyFido2Challenge(
                    result,
                    credential.email,
                    user.id,
                ).then(([response, token]) => {
                    if (response === 'success') {
                        TokenController.storeToken(token);
                        setCurrentScreen('done');
                        setIsLoading(false);
                        setLoadingMessage(null);
                    }
                }).catch((err) => {
                    setIsLoading(false);
                    setLoadingMessage(null);
                    setMessage(`Verification Error: ${err.message}`);
                });
            }).catch((err) => {
                setIsLoading(false);
                setLoadingMessage(null);
                setMessage(`Local Challenge Error: ${err.message}`);
            });
        }).catch((err) => {
            setIsLoading(false);
            setLoadingMessage(null);
            setMessage(`Chalenge Request Error: ${err.message}`);
        });
    }

    const renderScreen = (currentScreen) => {
        switch (currentScreen) {
            case 'done': 
                return (
                    <>
                        <h3>All done</h3>
                        <p>
                            Congrats! You've now got one of the finest security setup in the land!
                        </p>
                        <PaddedContainer>
                            <LargeButton
                                onClick={handleGoBackClicked}
                            >
                                Go back
                            </LargeButton>
                        </PaddedContainer>
                    </>
                )
            case 'validateFido':
                return (
                    <>
                        <h3>Device Verification</h3>
                        <p>
                            We've stored your info. Let's set up a challenge to test.
                        </p>
                        {
                            isLoading &&
                            <PaddedContainer>
                                {loadingMessage || 'Loading...'}
                            </PaddedContainer>
                        }

                        {
                            message && 
                            <PaddedContainer>
                                { message || 'There was a problem.' }
                            </PaddedContainer>
                        }
                        
                        <PaddedContainer>
                            <LargeButton
                                onClick={handleTestChallengeClicked}
                            >
                                Verify
                            </LargeButton>
                        </PaddedContainer>
                    </>
                )
            case 'askForCredentials':
                return (
                    <>
                        <h3>
                            Device Enrollment
                        </h3>

                        <p>If you haven't already been prompted, activate your hardware device, pushing any primary buttons that may be present</p>
                        <p>We'll wait here while you do so.</p>
                        {
                            message && 
                            <PaddedContainer>
                                Looks like there was a problem.
                                <LargeButton>
                                    Try Again?
                                </LargeButton>
                            </PaddedContainer>
                        }
                    </>
                );
            default:
                return (
                    <>
                        <h3>Introduction</h3>
                        <p>This is some exciting information about FIDO, and a FIDO/FIDO2 U2F device.</p>
                        <p>But, we should not be too wordy, and just encourage you to get started</p>
                        <ImportantParagraph>Important: You will need Chrome, Edge, Opera, or recent versions of Safari. Maybe we can determine that by the existence of <code>navigator.credentials</code>?</ImportantParagraph>
                        <PaddedContainer>
                            <LargeButton
                                onClick={handleGetStartedClicked}
                            >
                                Get Started
                            </LargeButton>
                        </PaddedContainer>
                    </>
                );
        }
    }
    return (
        <MainContent>
            <h2>Enroll FIDO Hardware Key</h2>
            { message || '' }
            {
                isLoading ? (loadingMessage || 'Getting things ready...') : renderScreen(currentScreen)
            }
        </MainContent>
    );
}

export default EnrollFido2;
