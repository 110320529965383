import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const SideBarContainer = styled.div`
    width: 20vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 16px;
    margin: 0 16px;
    transition: top 0.5s;

    @media screen and (max-width: 451px) {
        position: absolute;
        top: 0px;
        left: 0px;
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
        border-left: 1px solid #000;
    }
    
    ${(props) => props.shrink 
    ? css`
        top: -370px !important;
        width: initial;
        `
    : css`
        width: initial;
        `}
`;

const MenuItem = styled.button`
    font-family: QuickSand;
    font-weight: 100;
    font-size: 1rem;
    border: none;
    width: 100%;
    text-align: right;
    background-color: rgba(0,0,0,0);
    line-height: 2rem;
    ${(props) => props.active && css`
        font-weight: bold;
    `}
`;

const MobileOnlyMenuItem = styled.button`
    font-family: QuickSand;
    font-weight: 100;
    font-size: 1rem;
    border: none;
    width: 100%;
    text-align: right;
    background-color: rgba(0,0,0,0);
    line-height: 2rem;
    ${(props) => props.active && css`
        font-weight: bold;
    `}

    @media screen and (min-width: 451px) {
        display: none;
    }
`;

export const FACTORS_MENU = 'factors';
export const REDIRECTS_MENU = 'redirects';
export const DETAILS_MENU = 'userDetails';
export const LOGOUT_MENU = 'logout';
export const AUTHENTICATOR_MENU = 'authenticator';
export const REQUESTS_MENU = 'requestsMenu';
export const TEAM_MENU = 'teamsMenu';
export const AUDIT_MENU = 'security-audit';
export const PROVIDER_MENU = 'provider-menu';

function ProfileSideBar(props) {
    const [ current, setCurrent ] = useState(DETAILS_MENU);
    const [ isShrinked, setIsShrinked ] = useState(false);

    const color = localStorage.getItem('io.manyfactor.THEME') || 'black';
    
    const userStyle = {
        color : color === 'dark-mode' ? 'white' : color,
        backgroundColor: color === 'dark-mode' ? '#1e1f29' : 'white',
    };

    const handleMenuItemClicked = () => {
        setIsShrinked(true);
    }

    const handleUserDetailsClicked = () => {
        handleMenuItemClicked();
        setCurrent(DETAILS_MENU)
        props.onCurrentClicked(DETAILS_MENU);
    }

    const handleFactorsClicked = () => {
        handleMenuItemClicked();
        setCurrent(FACTORS_MENU)
        props.onCurrentClicked(FACTORS_MENU);
    }
    
    const handleLogoutClicked = () => {
        handleMenuItemClicked();
        setCurrent(LOGOUT_MENU)
        props.onCurrentClicked(LOGOUT_MENU);
    }

    const handleRedirectsClicked = () => {
        handleMenuItemClicked();
        setCurrent(REDIRECTS_MENU);
        props.onCurrentClicked(REDIRECTS_MENU);
    }

    const handleAuthenticatorClicked = () => {
        handleMenuItemClicked();
        setCurrent(AUTHENTICATOR_MENU);
        props.onCurrentClicked(AUTHENTICATOR_MENU);
    }

    const handleRequestsClicked = () => {
        handleMenuItemClicked();
        setCurrent(REQUESTS_MENU);
        props.onCurrentClicked(REQUESTS_MENU);
    }

    const handleAuditClicked = () => {
        handleMenuItemClicked();
        setCurrent(AUDIT_MENU);
        props.onCurrentClicked(AUDIT_MENU);
    }

    const handlePremiumClicked = () => {
        handleMenuItemClicked();
        setCurrent(PROVIDER_MENU);
        props.onCurrentClicked(PROVIDER_MENU);
    }

    const handleTeamClicked = () => {
        handleMenuItemClicked();
        setCurrent(TEAM_MENU);
        props.onCurrentClicked(TEAM_MENU);
    }

    return (
        <SideBarContainer
            shrink={isShrinked}
            style={userStyle}
        >
            <h2>Manyfactor.io</h2>
            
            <MenuItem
                active={current === DETAILS_MENU || props.current === DETAILS_MENU}
                onClick={handleUserDetailsClicked}
                style={userStyle}
            >
                User Details
            </MenuItem>

            <MenuItem
                active={current === REQUESTS_MENU || props.current === REQUESTS_MENU}
                onClick={handleRequestsClicked}
                style={userStyle}
            >
                2FA Inbox
            </MenuItem>

            <MenuItem
                active={current === REDIRECTS_MENU || props.current === REDIRECTS_MENU}
                onClick={handleRedirectsClicked}
                style={userStyle}
            >
                Redirects
            </MenuItem>

            <MenuItem
                active={current === AUTHENTICATOR_MENU || props.current === AUTHENTICATOR_MENU}
                onClick={handleAuthenticatorClicked}
                style={userStyle}
            >
                Cloud Authenticator
            </MenuItem>

            <MenuItem
                active={current === FACTORS_MENU || props.current === FACTORS_MENU}
                onClick={handleFactorsClicked}
                style={userStyle}
            >
                Login Security
            </MenuItem>

            <MenuItem
                active={current === TEAM_MENU || props.current === TEAM_MENU}
                onClick={handleTeamClicked}
                style={userStyle}
            >
                Team Security
            </MenuItem>

            <MenuItem
                active={current === AUDIT_MENU || props.current === AUDIT_MENU}
                onClick={handleAuditClicked}
                style={userStyle}
            >
                Audit Trail
            </MenuItem>

            <MenuItem
                active={current === PROVIDER_MENU || props.current === PROVIDER_MENU}
                onClick={handlePremiumClicked}
                style={userStyle}
            >
                Services
            </MenuItem>

            <MenuItem
                active={current === LOGOUT_MENU || props.current === LOGOUT_MENU}
                onClick={handleLogoutClicked}
                style={userStyle}
            >
                Sign Out
            </MenuItem>

            <MobileOnlyMenuItem
                onClick={() => setIsShrinked(!isShrinked)}
                style={userStyle}
            >
                <strong>{ isShrinked ? 'Show Menu' : 'Hide Menu' }</strong>
            </MobileOnlyMenuItem>
        </SideBarContainer>
    );
}

export default ProfileSideBar;
