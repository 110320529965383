import React, { useState, useEffect } from 'react';
import { MainContent, SmallButton, CenteredContainer, LargeButton, PaddedContainer, Input } from '../CommonStyles';
import { useHistory } from 'react-router-dom';
import { UserController, CredentialsController, ChallengeController } from '../../utils/apiProxy';
import { TokenController } from '../../utils/cachedAssets';

function EnrollYubikeyOtp() {
    const [ currentScreen, setCurrentScreen] = useState(null);
    const [ otp, setOtp ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ token, setToken ] = useState(null);
    const [ message, setMessage ] = useState(null);
    const [ credential, setCredential ] = useState(null);
    const [ user, setUser ] = useState(null);
    const [ otpVerify, setOtpVerify ] = useState(null);

    const history = useHistory();

    useEffect(() => {
        const token = TokenController.fetchToken();
        if (!token) {
            history.push('/signed-out');
        }

        setIsLoading(true);
        UserController.fetchUserDetails(token).then((result) => {
            setCredential(result.user.credential);
            setUser(result.user);
            setToken(token);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            const errorJsx = 
            <>
                { error.message}<br/>
                <button onClick={() => history.push('/auth')}>Log in again.</button>
            </>

            setMessage(errorJsx);
        });
    }, []);

    const handleFirstPress = (e) => {
        setOtp(e.target.value);
    }

    const handleFirstPressClicked = () => {
        setIsLoading(true);
        setMessage(null);

        CredentialsController.update(token, credential, {
            yubOtp: otp,
        }).then((credentialResponse) => {
            const { credential: newCredential } = credentialResponse;

            if (!newCredential.yubikeyIdentifier) {
                setMessage('Your Yubikey was not able to be registered. Try again.');
                setIsLoading(false);
                return;
            }

            ChallengeController.newYubikeyOtp(
                credential.email,
                user.id,
            ).then((_) => {
                setCredential(newCredential);
                setIsLoading(false);
                setMessage(null);
                setCurrentScreen('validateYubikey');
            });
        }).catch((error) => {
            setIsLoading(false);
            setMessage(error.message);
        });
    }

    const handleVerifyChanged = (e) => {
        setOtpVerify(e.target.value);
    }

    const handleVerifyClicked = () => {
        setIsLoading(true);
        setMessage(null);
        ChallengeController.validateYubikeyOtp(
            otpVerify,
            credential.email,
            user.id,
        ).then(([status, newToken]) => {
            if (status === 'success') {
                TokenController.storeToken(newToken);

                setIsLoading(false);
                setMessage(null);
                setCurrentScreen('done');
            }
        }).catch((error) => {
            setIsLoading(false);
            setMessage(`There was an issue validating your Yubikey: ${error.message}`);
        });
    }

    const handleDoneClicked = () => {
        // redirect back...?
        history.push('/me');
    }

    const renderCurrentScreen = (currentScreen) => {
        switch (currentScreen) {
            case 'done':
                return (
                    <>
                        <h3>Enrollment Complete!</h3>
                        <p>You're good to go! Yubikey OTP has been enrolled as a factor.</p>

                        <LargeButton
                            onClick={handleDoneClicked}
                        >
                            Done
                        </LargeButton>
                    </>
                );
            case 'validateYubikey': 
                return (
                    <>
                        <h3>Validate Yubikey OTP</h3>
                        <p>Great! We've enrolled your Yubikey, and set up a challenge.</p>
                        <p>Press the button again on your Yubikey to response to the challenge</p>
                        <PaddedContainer>
                            <Input 
                                onChange={handleVerifyChanged}
                                placeholder="Yubikey OTP"
                                style={{ textAlign: 'center' }}
                            />
                        </PaddedContainer>

                        <LargeButton
                            onClick={handleVerifyClicked}
                            disabled={!otpVerify || otpVerify.length === 0 || otpVerify.length < 44}
                        >
                            Verify
                        </LargeButton>
                    </>
                )
            case 'firstPress': 
                return (
                    <>
                        <h3>Register Device</h3>
                        <p>Press the Yubikey button, then click <strong>Save</strong>.</p>
                        
                        <PaddedContainer>
                            <Input 
                                onChange={handleFirstPress}
                                placeholder="Yubikey OTP"
                                style={{ textAlign: 'center' }}
                            />
                        </PaddedContainer>

                        <LargeButton
                            onClick={handleFirstPressClicked}
                            disabled={!otp || otp.length === 0 || otp.length < 44}
                        >
                            Save
                        </LargeButton>
                    </>
                );
            default:
                return (
                    <>
                        <h3>Introduction</h3>
                        <p>The Yubikey OTP is an older U2F hardware-based authentication protocol, but still pretty darn good. We will let you enroll your Yubikey OTP as a factor.</p>
                        <p>Have your Yubikey OTP inserted and ready, and click <strong>Next</strong>.</p>
                        <LargeButton onClick={() => setCurrentScreen('firstPress')}>
                            Next
                        </LargeButton>
                    </>
                )
        }
    }

    return (
        <MainContent>
            <h2>Enroll Yubikey OTP</h2>
            <PaddedContainer>
                <CenteredContainer>
                    { message }
                    { isLoading ? 'Getting things ready...' : renderCurrentScreen(currentScreen) }
                </CenteredContainer>
            </PaddedContainer>

        {
            currentScreen !== 'done' && 
            <SmallButton
                onClick={() => history.push('/me')}
            >
                Go back
            </SmallButton>
        }
        </MainContent>
    );
}

export default EnrollYubikeyOtp;