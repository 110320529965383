const TOKEN_KEY = 'io.manyfactor.TOKEN';

export const TokenController = {
    // TODO: set as a cookie; local storage is more prone to interception
    fetchToken: () => {
        const tokenString = sessionStorage.getItem(TOKEN_KEY);
        return tokenString || undefined;
    },

    storeToken: (token) => {
        sessionStorage.setItem(TOKEN_KEY, token);
    },

    removeToken: () => {
        sessionStorage.removeItem(TOKEN_KEY);
    }
}