import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import ProfileSideBar, { DETAILS_MENU, FACTORS_MENU, LOGOUT_MENU, REDIRECTS_MENU, AUTHENTICATOR_MENU, REQUESTS_MENU, TEAM_MENU, AUDIT_MENU, PROVIDER_MENU } from './ProfileSideBar';
import FactorsScreen from './FactorsScreen';
import SignoutScreen from './SignoutScreen';

import RedirectsScreen from '../Redirects/RedirectsScreen';

import { TokenController } from '../../utils/cachedAssets';
import { UserController, ProvidersController } from '../../utils/apiProxy';
import UserProfileScreen from './UserProfileScreen';
import TwoFactorRequestsScreen from './TwoFactorRequestsScreen';
import { ScreenContainer, MainContent } from '../CommonStyles';

const UserProfileContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    padding: 0 16px;
`;

function UserProfile() {
    const [ user, setUser ] = useState(null);
    const [ token, setToken ] = useState(null);
    const [ currentScreen, setCurrentScreen ] = useState(DETAILS_MENU);
    const [ message, setMessage ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ credential, setCredential ] = useState(null);
    const [ redirects, setRedirects ] = useState(null);
    const [ providers, setProviders ] = useState(null);

    const history = useHistory();

    useEffect(() => {
        const token = TokenController.fetchToken();

        if (token) {
            setIsLoading(true);
            UserController.fetchUserDetails(token).then((result) => {
                ProvidersController.fetchAllProviders().then((providers) => {
                    console.log('providers', providers);

                    setProviders(providers);

                    setUser(result.user);
                    setCredential(result.user.credential);
                    setRedirects(result.user.redirects);
                    setToken(token);
                    setIsLoading(false);
                });
            }).catch((error) => {
                setIsLoading(false);
                const errorJsx = 
                <>
                    { error.message}<br/>
                    <button onClick={() => history.push('/auth')}>Log in again.</button>
                </>
    
                setMessage(errorJsx);
            });
        } else {
            history.push('/signed-out');
        }
    }, []);

    const handleAddSmsClicked = () => {
        history.push('/me/enroll-sms');
    }

    const handleAddDeviceClicked = () => {
        history.push('/me/enroll-device');
    }

    const handleYubikeyOtpClicked = () => {
        history.push('/me/enroll-yubikeyotp');
    }

    const handleCurrentClicked = (currentScreen) => {
        setCurrentScreen(currentScreen);
    }

    const handleSignoutClicked = () => {
        TokenController.removeToken();
        history.push('/signed-out');
    }

    const handleFido2Clicked = () => {
        history.push('/me/enroll-fido2');
    }

    const handleRedirectAdded = () => {
        setIsLoading(true);

        UserController.fetchUserDetails(token).then((result) => {
            setUser(result.user);
            setCredential(result.user.credential);
            setRedirects(result.user.redirects);
            setToken(token);
            setIsLoading(false);

            setCurrentScreen(DETAILS_MENU);
        }).catch((error) => {
            setIsLoading(false);
            const errorJsx = 
            <>
                { error.message}<br/>
                <button onClick={() => history.push('/auth')}>Log in again.</button>
            </>

            setMessage(errorJsx);
            setCurrentScreen(DETAILS_MENU);
        });
    }

    const handleUserUpdated = () => {
        setIsLoading(true);
        UserController.fetchUserDetails(token).then((result) => {
            setUser(result.user);
            setCredential(result.user.credential);
            setRedirects(result.user.redirects);
            setToken(token);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            const errorJsx = 
            <>
                { error.message}<br/>
                <button onClick={() => history.push('/auth')}>Log in again.</button>
            </>

            setMessage(errorJsx);
        });
    }
    
    const renderCurrentScreen = (currentScreen) => {
        switch (currentScreen) {
            case TEAM_MENU: 
                return (
                    <ScreenContainer>
                        <h3>Teams</h3>
                        <p>This is where you can manage the details of other users in your care.</p>
                        <p>This is not a current beta feature, but is coming soon.</p>
                    </ScreenContainer>
                );
            case AUDIT_MENU:
                return (
                    <ScreenContainer>
                        <h3>Audit Trail</h3>
                        <p>This is where you can see the log of various events in your account, such as incoming and outgoing messages, login attempts, and the location these happened.</p>
                        <p>You can also choose to have these delivered to somewhere specific.</p>
                        <p>This feature is coming soon, and will be opt-in.</p>
                    </ScreenContainer>
                );
            case PROVIDER_MENU:
                return (
                    <ScreenContainer>
                        <h3>Manyfactor.io-as-a-Service</h3>
                        <p>This is where you can enroll in the premium feature where Manyfactor.io can provide various levels of Federated, Oauth2, or SAML authentication-and-authorization-as-a-service</p>
                        <p>This is not a current beta feature, but will be ready for the final launch.</p>
                    </ScreenContainer>
                );
            case REQUESTS_MENU:
                return (
                    <ScreenContainer>
                        <TwoFactorRequestsScreen
                            user={user}
                            currentScreen={currentScreen}
                            isLoading={isLoading}
                            redirects={redirects}
                            onScreenChanged={(screen) => setCurrentScreen(REDIRECTS_MENU)}
                            onRedirectDeleted={handleUserUpdated}
                        />
                    </ScreenContainer>
                )
            case AUTHENTICATOR_MENU:
                return (
                    <ScreenContainer>
                        <h2>Cloud Authenticator</h2>
                        <p>This is where your cloud authenticator lives, providing TOTP authentication, with safe backup in the cloud</p>
                        <p>This will be a beta feature, but is not quite ready yet.</p>
                    </ScreenContainer>
                );
            case REDIRECTS_MENU:
                return (
                    <>
                        <RedirectsScreen
                            isLoading={isLoading}
                            currentScreen={currentScreen}
                            message={message}
                            credential={credential}
                            user={user}
                            redirects={redirects}
                            providers={providers}
                            onDoneClicked={handleRedirectAdded}
                            onUserUpdated={handleUserUpdated}
                            devices={user.devices}
                        />
                    </>
                );
            case DETAILS_MENU:
                return (
                    <UserProfileScreen
                        isLoading={isLoading}
                        message={message}
                        currentScreen={currentScreen}
                        token={token}
                        user={user}
                    />
                );
            case FACTORS_MENU:
                return (
                    <FactorsScreen 
                        currentScreen={currentScreen}
                        message={message}
                        isLoading={isLoading}
                        credential={credential}
                        preferredLoginMethod={user?.preferredLoginMethod}
                        onAddSmsClicked={handleAddSmsClicked}
                        onYubikeyOtpClicked={handleYubikeyOtpClicked}
                        onUserUpdated={handleUserUpdated}
                        onFido2Clicked={handleFido2Clicked}
                        onAddDeviceClicked={handleAddDeviceClicked}
                        user={user}
                    />
                )
            case LOGOUT_MENU:
                return (
                    <SignoutScreen
                        currentScreen={currentScreen}
                        handleSignoutClicked={handleSignoutClicked}
                    />
                )
            default:
                return (
                    <>??</>
                );
        }
    }

    return (
        <UserProfileContainer>
            <ProfileSideBar
                onCurrentClicked={handleCurrentClicked}
                currentScreen={currentScreen}
            />

            <MainContent>
                { renderCurrentScreen(currentScreen) }
            </MainContent>
        </UserProfileContainer>
    );
}

export default UserProfile;