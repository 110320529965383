import { REDIRECTS_MENU, DETAILS_MENU, FACTORS_MENU, LOGOUT_MENU, REQUESTS_MENU } from "../components/UserProfile/ProfileSideBar";

export const formatSms = (sms) => {
    if (sms === '0000000000') {
        return ' a number available in 24-48 hours.';
    }
    
    if (!sms || !sms.length === 10) {
        return sms;
    }


    // TODO: use Regex instead
    const areaCode = sms.substr(0, 3);
    const exchange = sms.substr(3, 3);
    const subscriber = sms.substr(6, 4);

    return `${areaCode}-${exchange}-${subscriber}`;
}

export const cleanNumber = (incomingSms) => {
    const numberWithoutDashes = incomingSms.replace(/-/g, '');
    const last10Digits = numberWithoutDashes.replace(/.*(?=[0-9]{10})/, '');
    return last10Digits;
}

export const nameForCurrentScreen = (currentScreen) => {
    switch (currentScreen) {
        case REDIRECTS_MENU:
            return 'Redirects';
        case DETAILS_MENU:
            return 'User Profile';
        case FACTORS_MENU:
            return 'Factors';
        case LOGOUT_MENU:
            return 'Sign Out';
        case REQUESTS_MENU:
            return 'Two Factor Requests';
        default:
            return 'Manyfactor.io';
    }
}