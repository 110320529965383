import React from 'react';
import { ChromelessButton } from '../CommonStyles';

function TwoFactorRequests(props) {

    const handleClear2FAClicked = (request) => {
        console.log(request);
        props.onDeleteRequestClicked(request.id);
    }

    const renderActiveRequests = (activeRequests) => {
        return activeRequests.map((request) => (
            <div key={request.id} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <pre>
                    { JSON.stringify({...request, id: undefined}, null, '\t') }
                </pre>
                <ChromelessButton
                    onClick={() => handleClear2FAClicked(request)}
                >
                    Clear
                </ChromelessButton>
            </div>
        ));
    }

    return(
        <>
            <p>These are your current 2FA requests. Click to view; delete when you're done. They are also cleared out periodically.</p>
            {
                props.requests && props.requests.length
                    ? renderActiveRequests(props.requests) 
                    : <>Nothing here. Go <ChromelessButton onClick={props.onSetupRedirectClicked}>set up a redirect</ChromelessButton> first, and then set up your online accounts with the SMS/Email we've provided.</>
            }
        </>
    );
}

export default TwoFactorRequests;
