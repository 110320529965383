import React from 'react';
import { nameForCurrentScreen, formatSms } from '../../utils/stringHelpers';
import styled from 'styled-components';
import { SmallButton, ScreenContainer, FactorSelectionType } from '../CommonStyles';
import { ChallengeTypes, UserController } from '../../utils/apiProxy';
import { TokenController } from '../../utils/cachedAssets';

const CredentialSetContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const CredentialSet = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: #efefef;
    padding: 8px;
`;

const CredentialLabel = styled.span`
    font-weight: bold;
`;

const CredentialValue = styled.span`
`;

function FactorsScreen(props) {

    const handlePreferredMethodChanged = (preferredMethod) => {
        if (preferredMethod === props.preferredLoginMethod) {
            return;
        }

        UserController.update(TokenController.fetchToken(), { preferredLoginMethod: preferredMethod }).then((result) => {
            props.onUserUpdated();
        });
    }

    const renderFactorOptions = (credential) => {
        if (!credential) {

        }
        const { email, sms, yubikeyIdentifier, fidoCredentialId, deviceId } = credential;
        const { preferredLoginMethod } = props;

        const usedFactors = [];
        if (email) {
            usedFactors.push({email, type: ChallengeTypes.EMAIL});
        }
        if (sms) {
            usedFactors.push({sms, type: ChallengeTypes.SMS});
        }
        if (yubikeyIdentifier) {
            usedFactors.push({yubikeyIdentifier, type: ChallengeTypes.YUBIKEYOTP});
        }
        if (fidoCredentialId) {
            usedFactors.push({fidoCredentialId, type: ChallengeTypes.FIDO});
        }
        if (deviceId) {
            usedFactors.push({ deviceId, type: ChallengeTypes.DEVICE });
        }

        return usedFactors.map((factor) => {
            let isPreferred = preferredLoginMethod === factor.type
            return (
                <FactorSelectionType
                    key={factor.type}
                    tabIndex={0}
                    isPreferred={isPreferred}
                    onClick={() => handlePreferredMethodChanged(factor.type)}
                >
                    { factor.type }
                </FactorSelectionType>
            )
        });
    }

    if (!props.credential && !props.isLoading) {
        return (
        <ScreenContainer>
            <div>
                <h2>{ nameForCurrentScreen(props.currentScreen) }</h2>
            </div>
            <p>No credentials were returned.</p>
        </ScreenContainer>);
    }

    return (
        <ScreenContainer>
            <div>
                <h2>{ nameForCurrentScreen(props.currentScreen) }</h2>
            </div>

            <div>
                <p>This is the multifactor accounts page.</p>
                <p>Here, you can see the different accounts you can manage, and which ones are used for authentication on Manyfactor.io, as well as which are used for redirects.</p>
            </div>

            <div>
                { props.message } 
            </div>
            {
                props.isLoading ? 'Loading...' : 
                <>
                    <CredentialSetContainer style={{ color: 'black' }}>
                        <CredentialSet>
                            <CredentialLabel>Email:</CredentialLabel>
                            <CredentialValue>{ props.credential?.email || 'not set' }</CredentialValue>
                        </CredentialSet>
                    
                        <CredentialSet>
                            <CredentialLabel>SMS:</CredentialLabel>
                            <CredentialValue>
                                { 
                                    props.credential?.sms
                                    ? <><strong>+1 </strong>{ formatSms(props.credential.sms) }</>
                                    : <> not set </>
                                }
                                &nbsp;<SmallButton onClick={props.onAddSmsClicked}>{props.credential?.sms ? 'Change' : 'Add' }</SmallButton>
                            </CredentialValue>
                        </CredentialSet>
                     
                        <CredentialSet>
                            <CredentialLabel>YubiKey OTP:</CredentialLabel> 
                            <CredentialValue>
                                { 
                                    props.credential?.yubikeyIdentifier 
                                    || 
                                    <>
                                        not set 
                                    </>
                                }
                                &nbsp;<SmallButton onClick={props.onYubikeyOtpClicked}>{props.credential?.yubikeyIdentifier ? 'Change' : 'Add' }</SmallButton>
                            </CredentialValue>
                        </CredentialSet>

                
                        <CredentialSet>
                            <CredentialLabel>FIDO Hardware:</CredentialLabel> 
                            <CredentialValue>
                                { 
                                // TODO: this should be the device manfacturer, at some point...
                                    <>
                                        { props.credential?.fidoCredentialId ? 'Enrolled' : 'not set' }
                                    </>
                                }
                                &nbsp;<SmallButton onClick={props.onFido2Clicked}>{props.credential?.fidoCredentialId ? 'Change' : 'Add' }</SmallButton>
                            </CredentialValue>
                        </CredentialSet>

                        <CredentialSet>
                            <CredentialLabel>TPM Hardware:</CredentialLabel> 
                            <CredentialValue>
                                { 
                                // TODO: this should be the device manfacturer, at some point...
                                    <>
                                        { props.credential?.tpmFidoHardware ? 'Enrolled' : 'not set' }
                                    </>
                                }
                                &nbsp;<SmallButton disabled={true} onClick={props.onFido2Clicked}>{props.credential?.fidoCredentialId ? 'Change' : 'Add' }</SmallButton>
                            </CredentialValue>
                        </CredentialSet>

                        <CredentialSet>
                            <CredentialLabel>Mobile Device:</CredentialLabel> 
                            <CredentialValue>
                                { 
                                    props.user?.devices?.length > 0 
                                    ? props.user.devices[0].name
                                    :
                                    <>
                                        not set 
                                    </>
                                }
                                &nbsp;<SmallButton onClick={props.onAddDeviceClicked}>{props.credential?.deviceId ? 'Change' : 'Add' }</SmallButton>
                            </CredentialValue>
                        </CredentialSet>
                    
                    </CredentialSetContainer>
                
                    <h3>Preferred Login Factor</h3>
                    <p>You can set your preferred login factor:</p>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        { renderFactorOptions(props.credential) }
                    </div>
                
                    <h3>Secondary Login Factor</h3>
                    <p>You can set up an additional factor required for logging in. This is called <strong>Multi-factor Authentication</strong> or MFA.</p> 
                    <p>We're still setting this up; but, when finished, you'll be able to select from any of your primary factors, as well as neat things like IP-Address, Geolocation, and many others that are suitable for a secondary factor.</p>

                    <h3>Backup Login Factor</h3>
                    <p>If you forget, or no longer have access to your primary login factor, this will allow to to specify your backup factor.</p>
                    <p>It's not quite built yet; but this will be done soon.</p>
                </>
            }
        </ScreenContainer>
    );
}

export default FactorsScreen;