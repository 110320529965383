import React, { useState } from 'react';
import randomWords from 'random-words';

import { nameForCurrentScreen, formatSms, cleanNumber } from '../../utils/stringHelpers';
import { RedirectsController, ChallengeTypes, NumbersController, ProvidersController, SourceTypes, DevicesController } from '../../utils/apiProxy';
import { TokenController } from '../../utils/cachedAssets';

import {
    LargeButton,
    PaddedContainer,
    FlexRow,
    FullWidthDiv,
    StyledSelect,
    Form,
    Input,
    ChromelessButton,
    BoxContainer,
    ScreenContainer,
} from '../CommonStyles';


const SOURCE_EMAIL = 'email-challenge';
const SOURCE_SMS = 'sms-challenge';
const SOURCE_DEVICE = 'device-challenge';

function RedirectsScreen(props) {
    const { message, currentScreen, credential, providers, redirects } = props;

    const [ currentStep, setCurrentStep ] = useState();
    const [ source, setSource ] = useState(null);
    const [ sourceRegion, setSourceRegion ] = useState('any');
    const [ destination, setDestination] = useState(null);
    const [ sourceType, setSourceType ] = useState(null);
    const [ destinationType, setDestinationType ] = useState(null);
    const [ fee, setFee ] = useState(null);
    const [ isSaving, setIsSaving ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ isFindingNumber, setIsFindingNumber ] = useState(false);
    const [ foundNumber, setFoundNumber ] = useState(null);
    const [ selectedProvider, setSelectedProvider ] = useState(null);

    const handleProviderChanged = (e) => {
        if (!e || !e.target || !e.target.value) {
            return;
        }
        
        setErrorMessage(null);
        const chosenProvider = providers.find((p) => p.id === e.target.value);

        if (!chosenProvider) {
            return;
        }

        // do we already have a redirect for this?
        const existingProvider = redirects.find((r) => r.sharedSecondarySource === chosenProvider.phoneNumbers);
        if (existingProvider) {
            setSelectedProvider(null);
            setErrorMessage(`You already have a redirect for ${chosenProvider.description}`);
            return;
        }

        setSelectedProvider(chosenProvider);
    }

    const handleSelectRedirectTypesClicked = () => {
        if (destinationType !== ChallengeTypes.DEVICE) {
            setCurrentStep('selectSourceSettings');
            return;
        }

        setIsLoading(true);
        DevicesController.createNewDevice().then((newDevice) => {
            setIsLoading(false);
            setDestination(newDevice.id);
            setCurrentStep('selectSourceSettings');
        });
    }

    const handleDestinationSelected = () => {  
        if (destinationType !== ChallengeTypes.DEVICE) {
            setCurrentStep('selectDestinationSettings');
            return;
        }

        setIsLoading(true);
        DevicesController.createNewDevice().then((newDevice) => {
            setIsLoading(false);
            setDestination(newDevice.id);
            setCurrentStep('selectDestinationSettings');
        });
    }

    const handleSelectedProviderChosen = () => {
        setIsFindingNumber(true);
        setCurrentStep('selectSharedNumber');
        setFee(5);

        ProvidersController.getNumberForProvider(selectedProvider.phoneNumbers).then((result) => {

            const isExistingNumber = Boolean(result.phoneNumber);
            setSourceType(SourceTypes.SHARED_SMS);
            setFoundNumber(isExistingNumber ? result.phoneNumber : '0000000000');
            
            setIsFindingNumber(false);
        });
    }

    const handleSelectSourceSMSClicked = () => {
        setIsFindingNumber(true);
        setErrorMessage(null);

        NumbersController.searchNumber(sourceRegion).then((result) => {
            setIsFindingNumber(false);
            setSourceType(SourceTypes.SMS);
            setFoundNumber(result.phoneNumber);
            setCurrentStep('numberRequested');

        }).catch((error) => {
            setIsLoading(false);
            setErrorMessage(`There was an error: ${error.message}`);
        });
    }

    const handleSelectSourceEmailClicked = () => {
        const generatedEmail = `${randomWords(3).join('-')}@manyfactor.io`;
        setSource(generatedEmail);
        setSourceType(ChallengeTypes.EMAIL);
        setCurrentStep('selectDestinationSettings');
    }

    const handleSetDestinationClicked = () => {
        setCurrentStep('review');
    }

    const handleSaveRedirectClicked = () => {
        setIsSaving(true);
        setErrorMessage(null);

        if (selectedProvider) {
            // we are using an existing number
            RedirectsController.add({
                source,
                destination,
                sourceType,
                destinationType,
                sharedSecondarySource: selectedProvider.phoneNumbers,
            }, props.user.id).then((_) => {
                setIsSaving(false);
                setCurrentStep('done');
            });
        } else if (foundNumber) {
            // we are purchasing a number
            NumbersController.purchaseNumber(TokenController.fetchToken(), foundNumber)
                .then((purchaseResult) => {
                    if (purchaseResult) {
                        RedirectsController.add({
                            source,
                            destination,
                            destinationType,
                            sourceType,
                        }, props.user.id)
                            .then((_) => {
                               setIsSaving(false);
                                setCurrentStep('done');
                        });
                } else {
                    setIsSaving(false);
                    setErrorMessage('Touble purchasing number.');
                }
            })
        } else {
            // we are forwarding an email
            RedirectsController.add({
                source,
                destination,
                sourceType,
                destinationType,
            }, props.user.id).then((_) => {
                setIsSaving(false);
                setCurrentStep('done');
            });
        }
    }

    const handleDestinationChanged = (e) => {
        const { value } = e.target;
        
        if (value === credential?.sms) {
            setDestinationType(ChallengeTypes.SMS);
            setDestination(credential.sms);
        } else if (value === credential?.email) {
            setDestinationType(ChallengeTypes.EMAIL);
            setDestination(credential.email);
        } else if (value === ChallengeTypes.EMAIL) {
            setDestinationType(ChallengeTypes.EMAIL);
            setDestination(SOURCE_EMAIL);
        } else if (value === ChallengeTypes.SMS) {
            setDestinationType(ChallengeTypes.SMS);
            setDestination(SOURCE_SMS);
        } else if (value === ChallengeTypes.KEEP) {
            setDestinationType(ChallengeTypes.KEEP);
            setDestination('Kept on the Server');
        } else if (value === ChallengeTypes.DEVICE) {
            setDestinationType(ChallengeTypes.DEVICE);
            setDestination(SOURCE_DEVICE)
        }
    }

    const handleSourceChanged = (e) => {
        const { value } = e.target;
        setSourceType(value);
    }

    const valueForDestination = () => {
        if (credential) {
            if (destination === credential?.email) {
                return credential?.email;
            } else if (destination === credential?.sms) {
                return credential?.sms;
            }
        }

        return destinationType;
    }

    const handleDoneClicked = () => {
        setErrorMessage(null);
        props.onDoneClicked();
    }

    const handleDeleteRedirect = (redirectId) => {
        if (window.confirm('Are you sure you want to delete this?')) {
            setIsLoading(true);
            RedirectsController.delete(redirectId, props.user.id).then((_) => {
                props.onUserUpdated();
                setIsLoading(false);
            });
        }
    }

    const handleSelectDestinationClicked = () => {
        setSource(cleanNumber(foundNumber));
        setCurrentStep('selectDestinationSettings');
    }

    const handleSharedProviderConfirmed = () => {
        setSource(cleanNumber(foundNumber));
        setCurrentStep('selectDestination');
    }

    // const handleBrowserNotificationOptInClicked = () => {
    //     if (window.OneSignal) {
    //         window.OneSignal.push(() => {
    //             window.OneSignal.provideUserConsent(true);
    //             window.OneSignal.setExternalUserId(props.user.id);
    //         });
    //     }
    // }

    const renderRedirectHome = () => {
        const { redirects } = props;
        if (!redirects) {
            return null;
        }

        const redirectsJsx = redirects.map((redirect) => {
            let sourceJsx;

            if (redirect.sourceType === SourceTypes.SMS) {
                sourceJsx = formatSms(redirect.source);
            } else if (redirect.sourceType === SourceTypes.SHARED_SMS) {
                sourceJsx = <>{formatSms(redirect.source)} ({redirect.sharedSecondarySource})</>
            } else {
                sourceJsx = redirect.source;
            }

            let destinationJsx;

            switch (redirect.destinationType) {
                case ChallengeTypes.SMS:
                    destinationJsx = formatSms(redirect.destination);
                    break;
                case ChallengeTypes.KEEP:
                    destinationJsx = <>Kept on the server</>;
                    break;
                case ChallengeTypes.DEVICE:
                    const device = props.devices.find((d) => d.id === redirect.destination);
                    destinationJsx = <>To <b>{ device.name ? <>&quot;{device.name}&quot;</> : 'your mobile device'}</b></>;
                    break;
                default:
                    destinationJsx = redirect.destination   
            }
            
            return (
                <FlexRow key={redirect.id}>
                    <div style={{ width: '100%'}}>
                        <strong>{sourceJsx}</strong>
                    </div>
                    <div style={{ width: '100%'}}>
                        is forwarded to
                    </div>
                    <div style={{ width: '100%'}}>
                        <strong>{destinationJsx}</strong>
                    </div>

                    <div>
                        <FlexRow>
                            <ChromelessButton
                                onClick={() => window.alert('Woops. Can\'t update yet. Instead, you should delete this factor, and set up another one.')}
                            >
                                Update
                            </ChromelessButton>
                            &nbsp;|&nbsp;
                            <ChromelessButton
                                onClick={() => handleDeleteRedirect(redirect.id)}
                            >
                                Delete
                            </ChromelessButton>
                        </FlexRow>
                    </div>
                </FlexRow>
            )
        });

        return (
            <>
            <PaddedContainer>
                { redirectsJsx }
            </PaddedContainer>
            <LargeButton
                onClick={ () => setCurrentStep('selectProvider') }
            >
                Add {redirects && redirects.length ? 'another' : 'your first' } redirect
            </LargeButton>
            {/* <div>
                <p>
                    Also send a browser notification to this browser
                </p>
                <LargeButton
                    onClick={handleBrowserNotificationOptInClicked}
                >
                    Subscribe to Notifications
                </LargeButton>
            </div> */}
            </>
        )
    }

    const renderStep = (currentStep) => {
        switch (currentStep) {
            case 'done':
                return  (
                    <>
                        <h3>Done</h3>
                        <PaddedContainer>
                            <p>Your redirect is ready to use!</p>
                            <LargeButton
                                onClick={handleDoneClicked}
                            >
                                Back to Profile
                            </LargeButton>
                        </PaddedContainer>
                    </>
                )
            case 'selectSharedNumber':
                return isFindingNumber ? (
                    <>
                        Finding a free number for {selectedProvider.name}...
                    </>
                ) : (
                    <>
                        We have reserved a number for you to use.&nbsp;    
                        { foundNumber === '0000000000' ? <span>It will be available in 24-48 hours.</span> : <span>You can use it right away.</span> }

                        <PaddedContainer>
                            <LargeButton onClick={handleSharedProviderConfirmed}>
                                Next
                            </LargeButton>
                        </PaddedContainer>

                        <PaddedContainer>
                            <ChromelessButton onClick={() => setCurrentStep('selectProvider')}>
                                Back
                            </ChromelessButton>
                        </PaddedContainer>
                    </>
                )
            case 'numberRequested':
                return (
                    <>
                        <h3>Number request</h3>
                        
                        <div>
                            {
                                isFindingNumber
                                ? 'Searching for a number...'
                                : <>Found a great number: { formatSms(cleanNumber(foundNumber)) }</>
                            }
                        </div>

                        <PaddedContainer>
                            <LargeButton onClick={handleSelectDestinationClicked}>
                                Next
                            </LargeButton>
                        </PaddedContainer>
                        <PaddedContainer>   
                            <ChromelessButton onClick={handleSelectSourceSMSClicked}>
                                I want a different number...
                            </ChromelessButton>
                        </PaddedContainer>
                        <PaddedContainer>
                            <ChromelessButton onClick={() => setCurrentStep('selectSourceSettings')}>
                                Back
                            </ChromelessButton>
                        </PaddedContainer>
                    </>
                )
            case 'review':
                return (
                    <>
                        <h3>
                            Review your Redirect
                        </h3>
                        <PaddedContainer>
                            <p>You have chosen an incoming { sourceType === SourceTypes.SHARED_SMS ? selectedProvider.description : sourceType } 2FA request that will be delivered to <strong>{(sourceType === ChallengeTypes.EMAIL ? source : formatSms(source)) }</strong>.</p>
                            {
                                sourceType === SourceTypes.SHARED_SMS && <p>Only you will receive messages from <strong>{ selectedProvider.name }</strong> (via <strong>{ selectedProvider.phoneNumbers}</strong>) sent to this number.</p>
                            }

                            { 
                                destinationType === ChallengeTypes.KEEP && <p>We will keep those messages on the server. Login again to view them.</p>
                            }

                            {
                                destinationType === ChallengeTypes.SMS && <p>We will redirect that message to your mobile phone at <strong>{formatSms(destination)}</strong>.</p>
                            }

                            {
                                destinationType === ChallengeTypes.EMAIL && <p>We will redirect that message to your {destinationType} at <strong>{ChallengeTypes.EMAIL}</strong>.</p>
                            }            
                            {
                                destinationType === ChallengeTypes.DEVICE && <p>We will redirect that message to your Mobile device (name of device coming soon :)</p>
                            }                

                            <p>There will be a fee of <strong>{fee ? fee.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '0.00'}</strong></p>
                        </PaddedContainer>
                        <PaddedContainer>
                            <FlexRow>
                                <LargeButton onClick={() => setCurrentStep('selectDestinationSettings')}>
                                    Back
                                </LargeButton>

                                <LargeButton
                                    disabled={isSaving}
                                    onClick={handleSaveRedirectClicked}
                                >
                                    Save
                                </LargeButton>
                            </FlexRow>
                        </PaddedContainer>
                    </>
                );
            case 'selectDestination': 
                return (
                    <>
                        <FullWidthDiv>
                            <p>Select a destination:</p>
                                <StyledSelect
                                    value={valueForDestination() || undefined}
                                    onChange={handleDestinationChanged}
                                >
                                    <option value="">-- select --</option>
                                    <option value={ChallengeTypes.KEEP}>Keep the message here</option>
                                    { credential?.email && <option value={credential.email}>Your Email Address ({credential.email})</option> }
                                    <option value={ChallengeTypes.EMAIL}>A custom Email Address</option>
                                    { credential?.sms && <option value={credential.sms}>Your SMS ({formatSms(credential.sms)})</option> }
                                    <option value={ChallengeTypes.SMS}>A custom SMS</option>
                                    <option value={ChallengeTypes.DEVICE}>A mobile device</option>
                                </StyledSelect>
                        </FullWidthDiv>

                        <PaddedContainer>
                            <FlexRow>
                                <LargeButton
                                    disabled={!sourceType || !destinationType}
                                    onClick={handleDestinationSelected}
                                >
                                    Next
                                </LargeButton>
                            </FlexRow>
                            
                            <FlexRow>
                                <ChromelessButton onClick={() => setCurrentStep(null)}>Cancel</ChromelessButton>
                            </FlexRow>
                        </PaddedContainer>
                    </>
                )
            case 'selectDestinationSettings':
                const QRCode = require('qrcode.react');

                return (
                    <>    
                        <h3>Configure the destination</h3>
                        {
                            destinationType === ChallengeTypes.DEVICE &&
                            <>
                                <p>Use the <strong>Manyfactor.io Companion</strong> app to scan this QR Code. You will be provided further instructions.</p>
                                <p>When finished, click on &quot;Next&quot;</p>
                                <p><QRCode value={destination} renderAs="svg" /></p>
                            </>
                        }

                        {
                            destinationType === ChallengeTypes.KEEP && 
                                <>
                                    <p>We will keep these messages on the server. You will need to login every time you want to view them.</p>
                                    <p>This keeps those types of messages safely guarded, provided your account has a sufficient level of security.</p>
                                    <p>In the future, this option will also allow you to view your 2FA requests from your mobile device using an App.</p>
                                </>
                        }

                        {
                            (destinationType === ChallengeTypes.EMAIL || destinationType === ChallengeTypes.SMS)
                            &&
                            <>
                                <p>We will send incoming messages to <strong>{ (destinationType === ChallengeTypes.SMS ? formatSms(destination) : destination ) || <>the value below</>}</strong></p>

                                <Form>
                                    <Input
                                        type="email"
                                        placeholder="your email address"
                                        onChange={(e) => setDestination(e.target.value)}
                                        value={destination === SOURCE_EMAIL ? '' : destination}
                                    />
                                </Form>
                            </>
                        }

                        <PaddedContainer>
                            <FlexRow>
                                <LargeButton onClick={() => setCurrentStep('selectSourceSettings')}>
                                    Back
                                </LargeButton>

                                <LargeButton
                                    onClick={handleSetDestinationClicked}
                                    disabled={destination === ''}
                                >
                                    Next
                                </LargeButton>
                            </FlexRow>
                        </PaddedContainer>
                    </>
                )
            case 'selectSourceSettings':
                return (
                    <>
                        <h3>Configure the source</h3>
                        <p>You have selected { sourceType } as the source.</p>
                        {
                            props.user.phoneNumbers && props.user.phoneNumbers.length && sourceType === ChallengeTypes.SMS
                            ? <p><strong>You already have a purchased number. During the beta, consider just using the one number.</strong></p>
                            :  sourceType === ChallengeTypes.SMS ?
                            <>
                                {
                                    isFindingNumber 
                                    ? <>Finding an available number in { sourceRegion.toUpperCase() }...</>
                                    : 
                                        <PaddedContainer>
                                            <p>Select a region:</p>
                                            <StyledSelect onChange={(e) => setSourceRegion(e.target.value)}>
                                                <option value="any">Doesn't matter</option>
                                                <option value="ab">Alberta</option>
                                                <option value="bc">British Columbia</option>
                                                <option value="mb">Manitoba</option>
                                                <option value="nb">New Brunswick</option>
                                                <option value="nf">Newfoundland/Labrador</option>
                                                <option value="ns">Nova Scotia</option>
                                                <option value="nt">Nunavut</option>
                                                <option value="on">Ontario</option>
                                                <option value="qc">Quebec</option>
                                                <option value="pe">Prince Edward Island</option>
                                                <option value="sk">Saskatchewan</option>
                                                <option value="yk">Yukon/North West Territories</option>
                                            </StyledSelect>
                                        </PaddedContainer>
                                }
                            </>
                            :
                            <>
                                <p>We will generate an email address for you.</p>
                            </>
                        }

                        <PaddedContainer>
                            <FlexRow>
                                <LargeButton onClick={() => setCurrentStep('selectProvider')}>
                                    Back
                                </LargeButton>

                                <LargeButton
                                    onClick={sourceType === ChallengeTypes.SMS ? handleSelectSourceSMSClicked : handleSelectSourceEmailClicked}
                                    disabled={!sourceRegion || (sourceType === ChallengeTypes.SMS && props.user.phoneNumbers && props.user.phoneNumbers.length)}
                                >
                                    Next
                                </LargeButton>
                            </FlexRow>
                        </PaddedContainer>

                    </>
                )
            case 'selectProvider':
                return (
                    <>
                        <BoxContainer>
                            <div>
                                <h3>Select a provider</h3>
                                <StyledSelect value={selectedProvider?.id || undefined} onChange={handleProviderChanged}>
                                    <option>-- select a provider --</option>
                                    {
                                        props.providers.map((provider) => {
                                            return (
                                                <option key={provider.id} value={provider.id}>{provider.name}</option>
                                            )
                                        })
                                    }
                                </StyledSelect>
                            </div>
                            
                            {
                                selectedProvider ? (
                                    <div>
                                        <p><strong>{selectedProvider.description}</strong><br/>
                                        SMS from <strong>{selectedProvider.phoneNumbers}</strong></p>

                                        <PaddedContainer>
                                            <FlexRow>
                                                <LargeButton
                                                    disabled={!selectedProvider}
                                                    onClick={handleSelectedProviderChosen}
                                                >
                                                    Next
                                                </LargeButton>
                                            </FlexRow>
                                        </PaddedContainer>
                                    </div>
                                ) : null
                            }

                            <div>
                                <p>The point of selecting a provider is to simplify the wizard -- some providers only send 2FA over Email, or may have some options for which we can recommend options for.</p>
                                <p>Additionally, some providers send a 2FA only via an SMS Shortcode (Eg, Twitter, for instance), and some send using a VoIP 10 digit code (Eg, DigitalOcean). We can receive VoIP codes for free (create a custom redirect); but to receive a short code, we will have to provision a real cellular number, which will incur a charge.</p>
                            </div>
                        </BoxContainer>
                        <strong>or...</strong>
                        <BoxContainer>
                            <h3>Add a custom redirect</h3>
                            <FlexRow>
                                <FullWidthDiv>
                                    <StyledSelect
                                        onChange={handleSourceChanged}
                                        value={sourceType || undefined}
                                    >
                                        <option value="">-- select --</option>
                                        <option value={ChallengeTypes.EMAIL}>An incoming Email 2FA Challenge</option>
                                        <option value={ChallengeTypes.SMS}>An incoming SMS 2FA Challenge</option>
                                    </StyledSelect>
                                </FullWidthDiv>

                                <FullWidthDiv>
                                    will redirect to...
                                </FullWidthDiv>

                                <FullWidthDiv>
                                    <StyledSelect
                                        value={valueForDestination() || undefined}
                                        onChange={handleDestinationChanged}
                                    >
                                        <option value="">-- select --</option>
                                        <option value={ChallengeTypes.KEEP}>Keep the message here</option>
                                        { credential?.email && <option value={credential.email}>Your Email Address ({credential.email})</option> }
                                        <option value={ChallengeTypes.EMAIL}>A custom Email Address</option>
                                        { credential?.sms && <option value={credential.sms}>Your SMS ({formatSms(credential.sms)})</option> }
                                        <option value={ChallengeTypes.SMS}>A custom SMS</option>
                                        <option value={ChallengeTypes.DEVICE}>A Mobile Device</option>
                                    </StyledSelect>
                                </FullWidthDiv>
                            </FlexRow>
                        </BoxContainer>

                        <PaddedContainer>
                            <FlexRow>
                                <LargeButton
                                    disabled={!sourceType || !destinationType || isLoading}
                                    onClick={handleSelectRedirectTypesClicked}
                                >
                                    {isLoading ? 'Loading...' : 'Next' }
                                </LargeButton>
                            </FlexRow>
                            
                            <FlexRow>
                                <ChromelessButton onClick={() => setCurrentStep(null)}>Cancel</ChromelessButton>
                            </FlexRow>
                        </PaddedContainer>
                    </>
                );
            default:
                return (
                    <div>
                        <p><strong>Redirects</strong> are the flagship feature of Manyfactor.io. With a redirect, you can receive an anonymous email or phone number to receive two-factor-authentication requests from services, and have them stored in your account, able to receive only when you are logged in.</p>
                        <p>Optionally, we can also forward these two-factor requests to one of the validated destinations you have setup in <strong>Login Security</strong>.</p>
                        <p>To set up a redirect:</p>
                        <ul>
                            <li>Click the button below</li>
                            <li>Choose how to receive a two-factor code (an Email, or an SMS)</li>
                            <li>Select where to deliver it (A personal email address, a personal SMS, or just keep on the server (<strong>recommended</strong>).</li>
                            <li>A <strong>premium feature</strong> in the future will be to allow you to deliver it to a GROUP of people (eg, to two or more personal SMS or Email addresses)</li>
                        </ul>

                        { renderRedirectHome() }
                    </div>
                );
        }
    }

    return (
        <ScreenContainer>
            <div>
                <h2> { nameForCurrentScreen(currentScreen) }</h2>
            </div>

            <div>
                { isLoading || props.isLoading ? 'Loading...' : message }
            </div>

            { 
                errorMessage && 
                <div>
                    <strong>{ errorMessage }</strong>
                </div>
            }
            
            {
                props.isLoading ? null : renderStep(currentStep)
            }
        </ScreenContainer>
    );
}

export default RedirectsScreen;