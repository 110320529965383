import React from 'react';
import { nameForCurrentScreen } from '../../utils/stringHelpers';
import { LargeButton, PaddedContainer, ScreenContainer } from '../CommonStyles';

function SignoutScreen(props) {
    const { currentScreen, handleSignoutClicked } = props;

    return (
        <ScreenContainer>
            <div>
                <h2>{ nameForCurrentScreen(currentScreen) }</h2>
            </div>
            <div>
                Are you sure you want to sign out?
            </div>
            <PaddedContainer>
                <LargeButton
                    onClick={handleSignoutClicked}
                >
                    Sign Out
                </LargeButton>
            </PaddedContainer>
        </ScreenContainer>
    )
}

export default SignoutScreen;
