import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Authentication from '../Authentication/Authentication';
import UserProfile from '../UserProfile/UserProfile';
import EnrollSms from '../EnrollSms/EnrollSms';
import FrontEnd from '../FrontEnd/FrontEnd';
import EnrollYubikeyOtp from '../EnrollYubikeyOtp/EnrollYubikeyOtp';
import EnrollFido2 from '../EnrollFido2/EnrollFido2';
import SignedOut from '../Authentication/SignedOut';
import EnrollDevice from '../EnrollDevice/EnrollDevice';

function MainRouter() {
    const color = localStorage.getItem('io.manyfactor.THEME') || 'black';

    return (
        <BrowserRouter>
                <div id="theme-provider" style={{ minHeight: '100vh', minWidth: '100vw', maxWidth: '100vw', color : color === 'dark-mode' ? 'white' : color, backgroundColor: color === 'dark-mode' ? '#1e1f29' : 'white' }}>
                <Switch>
                    <Route path="/me/enroll-sms" exact={true}>
                        <EnrollSms />
                    </Route>

                    <Route path="/me/enroll-yubikeyotp" exact={true}>
                        <EnrollYubikeyOtp />
                    </Route>

                    <Route path="/me/enroll-fido2" exact={true}>
                        <EnrollFido2 />
                    </Route>

                    <Route path="/me/enroll-device" exact={true}>
                        <EnrollDevice />
                    </Route>

                    <Route path="/me" exact={true}>
                        <UserProfile />
                    </Route>

                    <Route path="/auth/:action">
                        <Authentication />
                    </Route>
                    
                    <Route path="/signed-out">
                        <SignedOut />
                    </Route>

                    <Route path="/">
                        <FrontEnd />
                    </Route>
                </Switch>
            </div>
        </BrowserRouter>
    )
}

export default MainRouter;