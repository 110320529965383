import React from 'react';
import { CenteredContainer, ChromelessButton } from '../CommonStyles';
import { useHistory } from 'react-router-dom';

import logo from '../logo.png';

function FrontEnd() {
    const history = useHistory();

    return (
        <CenteredContainer>
            <img src={logo} alt="Manyfactor.io"/>
            <div>
                Won't you <ChromelessButton onClick={() => history.push('/auth/signup')}>Signup</ChromelessButton>
            </div>
        </CenteredContainer>
    )
}

export default FrontEnd;