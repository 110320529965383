import styled, {css} from 'styled-components';

export const BoxContainer = styled.div`
  padding: 32px;
  border: 1px solid #efefef;
  margin: 16px auto;
`;

export const FirstThirdContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 25vw;

  @media screen and (max-width: 451px) {
    padding-right: 0;
  }
`;

export const LastThirdContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 25vw;

  @media screen and (max-width: 451px) {
    padding-left: 0;
  }
`;

/**
 * A large, primary-action button
 */
export const LargeButton = styled.button`
  border: 1px solid black;
  background-color: white;
  min-width: 250px;
  padding: 16px;
  font-size: 1.4rem;
`;

/**
 * A smaller button for secondary tasks
 */
export const SmallButton = styled.button`
    border: 1px solid black;
    background-color: white;
    padding: 4px 16px;
    font-size: 0.8rem;
`;

/**
 * A good, multi-purpse centered container with Flex
 */
export const CenteredContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ImportantParagraph = styled.p`
    background-color: #efefef;
    border: 1px solid #afafaf;
`;

export const MainContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ScreenContainer = styled.div`
    align-items: flex-start;
    width: 100%;
    @media screen and (max-width: 451px) {
      margin-top: 64px;
    }
`;
/**
 * A gray div with code within; like a <pre> but with auto-wrap
 */
export const CodeArea = styled.div`
    background-color: #efefef;
    padding: 16px;
    border: 1px solid #acacac;
    font-family: 'Courier New', Courier, monospace;
    max-width: 40vw;
    word-break: break-all;
`;

/**
 * Padded Container
 */
export const PaddedContainer = styled.div`
  margin: 16px auto;
  padding: 16px;
`;

/**
 * Standard Block Input Container
 */
export const Input = styled.input`
  padding: 16px;
  width: 50vw;
`;

/**
 * Large text to separate two blocks
 */
export const LargeBlockText = styled.div`
  font-size: 4rem;
`;

/**
 * A container for a row of elements
 */
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  
  @media screen and (max-width: 451px) {
    flex-direction: column !important;
    > div {
      text-align: center;

      > div {
        flex-direction: row !important;
      }
    }

    margin: 16px auto;
  }
`;


export const FactorSelectionType = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin: 16px;
  width: 128px;
  height: 128px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;

  ${(props) => props.isPreferred && css`border-width: 3px !important;`}
  ${(props) => props.isPreferred && css`font-weight: bold;`}

  @media screen and (max-width: 451px) {
    width: 64px;
    height: 64px;
  }
`;
/**
 * Standard full-width DIV
 */
export const FullWidthDiv = styled.div`
  width: 100%;
  text-align: center;
`;

/**
 * Full width Div, but with some standard padding
 */
export const PaddedFullWidthDiv = styled.div`
  width: 100%;
  margin: 0 8px;
  padding: 8px;
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    margin: 8px auto;
  }
`;

/**
 * Our nice styled select
 */
export const StyledSelect = styled.select`
  width: 100%;
  padding: 16px;
`;

/**
 * Button meant to resemble inline text
 */
export const ChromelessButton = styled.button`
  border: none;
  background-color: rgba(0,0,0,0);
  padding: 0;
  color: #319dfb;
  font-size: 1rem;
`;

/**
 * Block display error message
 */
export const ErrorMessage = styled.div`
margin: 16px;
padding: 16px;
background-color: #770000;
color: white;
`;