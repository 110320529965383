import React from 'react';
import { LargeButton, CenteredContainer, MainContent } from '../CommonStyles';
import { useHistory } from 'react-router-dom';

function SignedOut() {
    const history = useHistory();

    const handleSignInClicked = () => {
        history.push('/auth/login');
    }

    return (
        <MainContent>
            <h1>Manyfactor.io</h1>
            <h2>Signed Out</h2>
                <CenteredContainer>
                    <p>Well, it looks like you've been signed out.</p>
                    <p>Hope to see you soon.</p>
                </CenteredContainer>

                <LargeButton onClick={handleSignInClicked}>
                    Sign In Again
                </LargeButton>
        </MainContent>
    )
}

export default SignedOut;