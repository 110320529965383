import React, { useEffect, useState } from 'react';
import { nameForCurrentScreen } from '../../utils/stringHelpers';
import TwoFactorRequests from '../TwoFactorRequests/TwoFactorRequests';
import { RedirectsController } from '../../utils/apiProxy';
import { TokenController } from '../../utils/cachedAssets';
import { ScreenContainer } from '../CommonStyles';

function TwoFactorRequestsScreen(props) {

    const [ activeRequests, setActiveRequests ] = useState([]);
    const [ isRedirectsLoading, setIsRedirectsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);

    const handleSetupRedirectClicked = () => {
        props.onScreenChanged('redirects');
    }

    const handleDeleteRequestClicked = (requestId) => {
        RedirectsController.deleteOpenRequest(TokenController.fetchToken(), requestId).then(() => {
            props.onRedirectDeleted();
        });
    }

    useEffect(() => {
        if (!props.redirects) {
            return;
        }

        if (props.redirects.length > 0) {
            async function getRequests(redirects, cb) {
                let theseRequests = [];
                for (let x = 0; x < props.redirects.length; x++) {
                    try {
                        const newRequests = await RedirectsController.listOpenRedirects(TokenController.fetchToken(), redirects[x].id);
                        theseRequests = [...theseRequests, ...newRequests];
                    } catch (e) {
                        console.log('e', e);
                        
                        // throw new Error(e.message);
                    }
                }

                console.log('theseRequests', theseRequests);

                cb(theseRequests);
            }

            setIsRedirectsLoading(true);
            try {
                getRequests(props.redirects, (theRequests) => {
                    console.log('theRequests', theRequests);
                    setActiveRequests(theRequests);
                });
            } catch (e) {
                console.log('there was an error', e);
                setErrorMessage(e.message);
            }

            setIsRedirectsLoading(false);
        }
    }, props.redirects);

    return (
        <ScreenContainer>
            <h2>{ nameForCurrentScreen(props.currentScreen) }</h2>
            
            { props.errorMessage || errorMessage }

            {
                (props.isLoading || isRedirectsLoading)
                ? 'Loading..'
                : <TwoFactorRequests
                    requests={activeRequests}
                    onSetupRedirectClicked={handleSetupRedirectClicked}
                    onDeleteRequestClicked={handleDeleteRequestClicked}
                />
            }
        </ScreenContainer>
    )
}

export default TwoFactorRequestsScreen;
