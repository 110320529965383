import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import OTPInput from 'otp-input-react';

import { TokenController } from '../../utils/cachedAssets';
import { UserController, CredentialsController, ChallengeController } from '../../utils/apiProxy';
import { MainContent, SmallButton, LargeButton } from '../CommonStyles';

const supportedAreaCodes = [
    306, // sk
    639, // also sk
    
    204, // mb
    431, // also mb

    780, // ab
    587, // ab
    825, // ab
    
    250, // bc
    236, // bc
    778, // bc
    604, // vancouver island
    
    807, // western on
    705, // northern on
    249, // nothern on
    519, // southern on
    226, // southern on
    548, // southern on
    416, // to
    437,// to
    647,// to
    905,// to
    289,// to
    365, // to

    819, // northern qc
    837, // northern qc
    450, 
    579,
    514,
    438,
    418,
    367,
    581,
    506,
    902,
    782,
    709,
    867, // yc
];

const EnrollSmsContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    padding: 0 16px;
`;

const Content = styled.div`
    margin: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

function EnrollSms() {
    const [ user, setUser ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ credential, setCredential ] = useState(null);
    const [ message, setMessage ] = useState(null);
    const [ currentScreen, setCurrentScreen ] = useState(null);
    const [ newSms, setNewSms ] = useState(null);
    const [ verifyCode, setVerifyCode ] = useState(null);

    const history = useHistory();

    useEffect(() => {
        const savedToken = TokenController.fetchToken();
        if (!savedToken) {
            history.push('/signed-out');
        }

        setMessage('Getting things ready...');
        UserController.fetchUserDetails(savedToken).then((result) => {
            setMessage(null);
            setUser(result.user);
            setCredential(result.user.credential);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);

            const errorJsx = 
            <>
                { error.message}<br/>
                <button onClick={() => history.push('/auth')}>Log in again.</button>
            </>

            setMessage(errorJsx);
        });
    }, []);
    
    const smsChanged = (newSms) => {
        setNewSms(newSms);
    }

    const validateSms = (sms) => {
        const areaCode = parseInt(sms?.slice(0, 3)) || null;

        const passedLength = (
            sms?.length
            && sms?.length === 10
        );

        const passedAreaCode =
            supportedAreaCodes.includes(areaCode);

        if (passedLength) {
            if (!passedAreaCode) {
                return [ false, 'Your area code is not supported'];
            };

            return [true, null];
        }

        return [ false, null ];
    }

    const sendSmsValidation = () => {
        setMessage('Requesting a challenge...');
        setIsLoading(true);
        ChallengeController.newSms(newSms, user.id).then((result) => {
            setMessage(null);
            setIsLoading(false);
            setCurrentScreen('verifySms');
        });
    }

    const handleVerifyCodeChanged = (code) => {
        setVerifyCode(code);
    }

    const handleVerifyCodeClicked = () => {
        setMessage('Verifying code...');
        setIsLoading(true);
        ChallengeController.validate(verifyCode, newSms, user.id).then(([result, token]) => {
            TokenController.storeToken(token);
            setMessage('Saving factor to profile...');

            CredentialsController.update(TokenController.fetchToken(), credential, { sms: newSms }).then((response) => {
                setIsLoading(false);
                setCurrentScreen('done');
            }).catch((error) => {
                setIsLoading(false);
                setMessage('There was a problem.');
            });
        });
    }

    const renderCredentialVerify = () => {
        return (
            <>
                <Content>
                    <Content>
                        We sent an SMS to {newSms}, please enter the code specified.
                    </Content>

                    <Content>
                        <OTPInput
                            value={verifyCode}
                            onChange={handleVerifyCodeChanged}
                            autoFocus
                            OTPLength={6}
                            otpType="number"
                            disabled={false}
                        />
                    </Content>

                    <Content>
                        {
                            verifyCode?.length === 6 &&
                            <LargeButton
                                disabled={isLoading}
                                onClick={handleVerifyCodeClicked}
                            >
                                Verify
                            </LargeButton>
                        }
                    </Content>
                </Content>
            </>
        )
    }

    const renderCredentialPrompt = () => {
        if (!credential) {
            return null;
        }

        const messageJsx = credential?.sms 
        ? <Content>Looks like you're already enrolled in SMS.<br/><br/>You can change your primary SMS number by entering it below:</Content>
        : <Content>Looks like we need to set you up with an SMS number.</Content>

        const [isSmsValid, invalidMessage ] = validateSms(newSms);

        return (
            <>
                <div>
                    { messageJsx }
                </div>

                { 
                !isLoading && 
                    <Content>
                        <OTPInput
                            value={newSms}
                            onChange={smsChanged}
                            autoFocus
                            OTPLength={10}
                            otpType="number"
                            disabled={false}
                        />
                    </Content>
                }

                <Content>
                    { 
                        isSmsValid 
                        ? 
                        <>
                            Looks good! Let's Validate!
                            <Content>
                                <LargeButton 
                                    disabled={isLoading}
                                    onClick={sendSmsValidation}
                                >
                                    Validate
                                </LargeButton>
                            </Content>
                        </>
                        : <>{ invalidMessage }</>
                    }

                </Content>
            </>
        )
    }

    const renderCurrentScreen = (currentScreen) => {
        switch (currentScreen) {
            case 'done':
                return (
                    <>
                        <Content>
                            <h2>Enroll in SMS Authentication</h2>
                            { user?.firstName } { user?.lastName }
                        </Content>

                        <Content>
                            Great news: we're all set! You can now use your SMS as an additional factor!
                        </Content>

                        <Content>
                            <LargeButton onClick={() => history.push('/me')}>
                                Back to Profile
                            </LargeButton>
                        </Content>
                    </>
                );
            case 'verifySms': 
                return (
                    <>
                        <Content>
                            <h2>Enroll in SMS Authentication</h2>
                            { user?.firstName } { user?.lastName }
                        </Content>

                        { message && 
                        <Content>
                            { message }
                        </Content>
                        }

                        <Content>
                            {
                                renderCredentialVerify()
                            }
                        </Content>
                    </>
                );
            default:
                return (
                <>
                    <Content>
                        <h2>Enroll in SMS Authentication</h2>
                        { user?.firstName } { user?.lastName }
                    </Content>

                    { message && 
                        <Content>
                            { message }
                        </Content>
                    }

                    <Content>
                        {
                            renderCredentialPrompt()
                        }
                    </Content>
                </>
                )
        }
    }

    return (
        <EnrollSmsContainer>
            <MainContent>
                { renderCurrentScreen(currentScreen) }
                <SmallButton onClick={() => history.push('/me')}>
                    Go back
                </SmallButton>
            </MainContent>
        </EnrollSmsContainer>
    );
}

export default EnrollSms;