import React from 'react';
import { nameForCurrentScreen } from '../../utils/stringHelpers';
import { ScreenContainer } from '../CommonStyles';

function UserProfileScreen(props) {
    const { currentScreen, user, isLoading, message, token } = props;

    return (
        <ScreenContainer>
            <div>
                <h2>{ nameForCurrentScreen(currentScreen) }</h2>
            </div>

            <div>
                <p>This is your user profile. Some day, we will let you edit it.</p>
            </div>

            <div>
                { isLoading ? 'Loading...' : message }
            </div>

            { 
            user && token && 
                <> 
                    <div>
                        <h3>User Details:</h3>
                        <p>
                            { user?.firstName }&nbsp;
                            { user?.lastName }
                        </p>
                    </div>

                    <div>
                        <h3>Favorite Colour</h3>
                        <p>
                            Express yourself! Pick a colour!
                        </p>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap',  }}>
                            {
                            [
                                'black',
                                'red',
                                'blue',
                                'orange',
                                'fuchsia',
                                'navy',
                                'tan',
                                'teal',
                                'dark-mode'
                            ].map((color) => (
                                <div 
                                    key={color}
                                    style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        textAlign: 'center',
                                        margin: 8,
                                        padding: 8,
                                        width: 64,
                                        height: 64,
                                        backgroundColor: color === 'dark-mode' ? 'black' : color,
                                        borderColor: 'black',
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        cursor: 'pointer',
                                        borderRadius: 8,
                                    }}
                                    onClick={() => { localStorage.setItem('io.manyfactor.THEME', color); window.location.href = '/me'; }}
                                >
                                    <span style={{ color: 'white', fontWeight: 'bold' }}>{color}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* <div>
                        <h3>Token:</h3>
                        <CodeArea>{token}</CodeArea>
                    </div> */}
                </>
            }

            <h3>What next?</h3>
            <p>To further secure your account login, head to the <strong>Login Security</strong> screen.</p>
            <p>To set up a 2FA redirector (allowing you to use an account we set you up with, instead of your personal email or phone), head to the <strong>Redirects</strong> screen.</p>
            <p>To see 2FA requests you've received, head to the <strong>2FA Requests</strong> screen.</p>
            <p>To set up your Manyfactor Authenticator, head to the <strong>Cloud Authenticator</strong> screen.</p>

        </ScreenContainer>
    );
}

export default UserProfileScreen;